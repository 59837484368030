import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './directory-map.css'

const DirectoryMap = (props) => {
  return (
    <div className="directory-map-container">
      <Helmet>
        <title>DirectoryMap - exported project</title>
        <meta property="og:title" content="DirectoryMap - exported project" />
      </Helmet>
      <img
        alt="pastedImage"
        src="/activity/directorymap_svg.svg"
        loading="eager"
        className="directory-map-pasted-image"
      />
      <Link to="/" className="directory-map-navlink">
        Link
      </Link>
    </div>
  )
}

export default DirectoryMap
