import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-01.css'

const DutyFree01 = (props) => {
  return (
    <div className="duty-free01-container">
      <Helmet>
        <title>免税</title>
        <meta property="og:title" content="DutyFree-01 - exported project" />
      </Helmet>
      <div className="duty-free01-container1">
        <img
          alt="image"
          src="/images/Duty free.svg"
          loading="eager"
          className="duty-free01-image"
        />
        <Link to="/duty-free-02" className="duty-free01-navlink">
          <img
            alt="image"
            src="/Homepage/black-square-for-overlay.svg"
            className="duty-free01-overlay"
          />
        </Link>
        <img
          alt="image"
          src="/images/Duty free select delivery.svg"
          loading="eager"
          className="duty-free01-image1"
        />
        <div className="duty-free01-container2"></div>
        <Link to="/duty-free-02" className="duty-free01-navlink1"></Link>
      </div>
    </div>
  )
}

export default DutyFree01
