import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './hotel-receipt.css'

const HotelReceipt = (props) => {
	return (
		<div className="hotel-receipt-container">
			<Helmet>
				<title>HotelReceipt - exported project</title>
				<meta property="og:title" content="HotelReceipt - exported project"/>
			</Helmet>
			<div className="hotel-receipt-container1">

					<img
					alt="image"
					src="/activity/hotel_receipt1.svg"
					className="hotel-receipt-image"
				/>
				<Link to="/" className="hotel-receipt-navlink">
				</Link>
			</div>
		</div>
	)
}

export default HotelReceipt
