import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './airport-transfer03.css'

const AirportTransfer03 = (props) => {
	return (
		<div className="airport-transfer03-container">
			<Helmet>
				<title>AirportTransfer03 - exported project</title>
				<meta
					property="og:title"
					content="AirportTransfer03 - exported project"
				/>
			</Helmet>
			<div className="airport-transfer03-container1">
				<div className="airport-transfer03-container2"></div>
				<img
					alt="image"
					src="/activity/airport-transfer-03.svg"
					className="airport-transfer03-image"
				/>
				<Link to="/airport-transfer02" className="airport-transfer03-navlink">
					Text
				</Link>
				<Link to="/airport-transfer04" className="airport-transfer03-navlink1">
					Text
				</Link>
			</div>
		</div>
	)
}

export default AirportTransfer03
