import React from 'react'

import { Helmet } from 'react-helmet'

import BottomNavBar from '../components/bottom-nav-bar'
import './more.css'

const More = (props) => {
  return (
    <div className="more-container">
      <Helmet>
        <title>More - exported project</title>
        <meta property="og:title" content="More - exported project" />
      </Helmet>
      <div className="more-container01">
        <h1 className="more-text">丹尼尔·奥斯曼</h1>
        <div className="more-container02">
          <img
            alt="image"
            src="/more_assets/more1.svg"
            className="more-image"
          />
          <div className="more-container03">
            <img
              alt="image"
              src="/more_assets/more2.svg"
              className="more-image01"
            />
            <span className="more-text01">
              推荐朋友即可获得一张 15% 折扣优惠券。
            </span>
            <img
              alt="image"
              src="/more_assets/white-arrow.svg"
              className="more-image02"
            />
          </div>
        </div>
        <div className="more-container04">
          <h2>
            <span>帐户</span>
            <br></br>
          </h2>
          <div className="more-container05">
            <span className="more-text05">个人信息</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image03"
            />
          </div>
          <div className="more-container06"></div>
          <div className="more-container07">
            <span className="more-text06">我的优惠券</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image04"
            />
          </div>
          <div className="more-container08"></div>
          <div className="more-container09">
            <span className="more-text07">电子钱包</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image05"
            />
          </div>
        </div>
        <div className="more-container10">
          <h2>
            <span>设置</span>
            <br></br>
          </h2>
          <div className="more-container11">
            <span className="more-text11">通知</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image06"
            />
          </div>
          <div className="more-container12"></div>
          <div className="more-container13">
            <span className="more-text12">语言</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image07"
            />
          </div>
          <div className="more-container14"></div>
          <div className="more-container15">
            <span className="more-text13">货币</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image08"
            />
          </div>
        </div>
        <div className="more-container16">
          <h2>
            <span>推荐</span>
            <br></br>
          </h2>
          <div className="more-container17">
            <span className="more-text17">
              <span>帮助中心</span>
              <br></br>
            </span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image09"
            />
          </div>
          <div className="more-container18"></div>
          <div className="more-container19">
            <span className="more-text20">FAQs</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image10"
            />
          </div>
          <div className="more-container20"></div>
          <div className="more-container21">
            <span className="more-text21">在线客服</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image11"
            />
          </div>
        </div>
        <div className="more-container22">
          <h2>
            <span>法律</span>
            <br></br>
          </h2>
          <div className="more-container23">
            <span className="more-text25">
              <span>隐私政策</span>
              <br></br>
            </span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image12"
            />
          </div>
          <div className="more-container24"></div>
          <div className="more-container25">
            <span className="more-text28">服务条款</span>
            <img
              alt="image"
              src="/more_assets/grey-arrow.svg"
              className="more-image13"
            />
          </div>
        </div>
      </div>
      <span className="more-log-out">退出登陆</span>
      <BottomNavBar rootClassName="bottom-nav-bar-root-class-name3"></BottomNavBar>
    </div>
  )
}

export default More
