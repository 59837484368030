import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './airport-transfer08.css'

const AirportTransfer08 = (props) => {
  return (
    <div className="airport-transfer08-container">
      <Helmet>
        <title>机场接送</title>
        <meta
          property="og:title"
          content="AirportTransfer08 - exported project"
        />
      </Helmet>
      <img
        alt="image"
        src="/activity/airport-transfer-08.svg"
        className="airport-transfer08-image"
      />
      <Link to="/" className="airport-transfer08-navlink">
        文本
      </Link>
    </div>
  )
}

export default AirportTransfer08
