import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-02.css'

const Bus02 = (props) => {
  return (
    <div className="bus02-container">
      <Helmet>
        <title>公交车</title>
        <meta property="og:title" content="Bus-02 - exported project" />
      </Helmet>
      <div className="bus02-container1">
        <div className="bus02-container2">
          <div className="bus02-container3">
            <Link to="/bus-01" className="bus02-navlink">
              <img
                alt="image"
                src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/Group 4515.svg"
                className="bus02-image"
              />
            </Link>
            <span className="bus02-text">联系方式</span>
          </div>
        </div>
        <div className="bus02-container4">
          <span className="bus02-text1">
          请填写联系方式。
          </span>
          <img
            alt="image"
            src="/images/Field Default.svg"
            className="bus02-image1"
          />
          <img
            alt="image"
            src="/images/Field Default (1).svg"
            className="bus02-image2"
          />
          <img
            alt="image"
            src="/images/Field Type.svg"
            className="bus02-image3"
          />
          <img
            alt="image"
            src="/images/Group 4583.svg"
            className="bus02-image4"
          />
          <div className="bus02-container5">
            <img
              alt="image"
              src="/images/Field Default (2).svg"
              className="bus02-image5"
            />
            <img
              alt="image"
              src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/expand_more_FILL0_wght400_GRAD0_opsz48 2.svg"
              className="bus02-image6"
            />
          </div>
        </div>
        <div className="bus02-container6">
          <img
            alt="image"
            src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/Component 19.svg"
            className="bus02-image7"
          />
          <Link to="/bus-03" className="bus02-navlink1">
            文本
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Bus02
