import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-06.css'

const DutyFree06 = (props) => {
  return (
    <div className="duty-free06-container">
      <Helmet>
        <title>免税</title>
        <meta property="og:title" content="DutyFree-06 - exported project" />
      </Helmet>
      <div className="duty-free06-container1">
        <img
          alt="image"
          src="/images/Duty free review.svg"
          className="duty-free06-image"
        />
        <Link to="/duty-free-05" className="duty-free06-navlink">
           
        </Link>
        <Link to="/duty-free-07" className="duty-free06-navlink1">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree06
