import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './activity-03.css'

const Activity03 = (props) => {
  return (
    <div className="activity03-container">
      <Helmet>
        <title>活动</title>
        <meta property="og:title" content="Activity-03 - exported project" />
      </Helmet>
      <img
        alt="pastedImage"
        src="/images/activity03.svg"
        loading="eager"
        className="activity03-pasted-image"
      />
      <Link to="/activity-02" className="activity03-navlink">
        链接
      </Link>
    </div>
  )
}

export default Activity03
