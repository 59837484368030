import React from 'react'

import { Helmet } from 'react-helmet'

import IgnoredBuggedUnableToDelete from '../components/ignored-bugged-unable-to-delete'
import BottomNavBar from '../components/bottom-nav-bar'
import './home-page.css'

const HomePage = (props) => {
  return (
    <div className="home-page-container">
      <Helmet>
        <title>导出的项目</title>
      </Helmet>
      <div className="home-page-frame">
        <img
          alt="image"
          src="/Homepage/mailbox-200h.png"
          className="home-page-notification"
        />
        <img
          alt="image"
          src="/Homepage/homepagescreen%20image-1500w.jpg"
          className="home-page-homepagebackground"
        />
        <div className="home-page-top-bottom-section">
          <span className="home-page-text">没有即将举办的活动</span>
          <span className="home-page-text01">你好，吴</span>
        </div>
        <div className="home-page-voucher">
          <div className="home-page-voucher-section">
            <div className="home-page-container1">
              <img
                alt="image"
                src="/Homepage/gift-200h.png"
                className="home-page-image"
              />
            </div>
            <div className="home-page-container2">
              <span className="home-page-text02">
                <span>第一次预订可享受15%的折扣。</span>
                <br></br>
              </span>
              <span className="home-page-text05">查看凭证</span>
            </div>
          </div>
        </div>
        <div className="home-page-one-stop-airport-services">
          <span className="home-page-text06">一站式机场服务</span>
          <nav className="home-page-container3 overflow">
            <img
              alt="image"
              src="/Homepage/group%204504-1400h.png"
              className="home-page-image1"
            />
            <img
              alt="image"
              src="/Homepage/airpot%20service%202-1400h.png"
              className="home-page-image2"
            />
            <img
              alt="image"
              src="/Homepage/aiport%20service%203-1400h.png"
              className="home-page-image3"
            />
          </nav>
        </div>
        <span className="home-page-text07">
          发现并计划您的下一次旅程
        </span>
        <IgnoredBuggedUnableToDelete rootClassName="ignored-bugged-unable-to-delete-root-class-name"></IgnoredBuggedUnableToDelete>
        <div className="home-page-recommended">
          <span className="home-page-text08">推荐给您</span>
          <nav className="home-page-container4 overflow">
            <img
              alt="image"
              src="/Homepage/travel-a-1200w.svg"
              className="home-page-image4"
            />
            <img
              alt="image"
              src="/Homepage/travel-b-1200w.svg"
              className="home-page-image5"
            />
          </nav>
        </div>
        <div className="home-page-explore-more">
          <span className="home-page-text09">探索更多</span>
          <nav className="home-page-container5 overflow">
            <span className="home-page-text10">全部</span>
            <span className="home-page-text11">食物&amp; 饮料</span>
            <span className="home-page-text12">爱慕</span>
            <span className="home-page-text13">活动</span>
          </nav>
        </div>
        <div className="home-page-container6">
          <img
            alt="image"
            src="/Homepage/group%204499-200w.png"
            className="home-page-image6"
          />
          <img
            alt="image"
            src="/Homepage/group%204500-200w.png"
            className="home-page-image7"
          />
        </div>
        <BottomNavBar rootClassName="bottom-nav-bar-root-class-name"></BottomNavBar>
      </div>
    </div>
  )
}

export default HomePage
