import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hotel-book.css'

const HotelBook = (props) => {
  return (
    <div className="hotel-book-container">
      <Helmet>
        <title>酒店预订</title>
        <meta property="og:title" content="HotelBook - exported project" />
      </Helmet>
      <div className="hotel-book-container1">
        <img
          alt="image"
          src="/images/hotel-book.svg"
          className="hotel-book-image"
        />
        <Link to="/hotel-select" className="hotel-book-navlink">
                
        </Link>
        <Link to="/hotel-payment" className="hotel-book-navlink1">
                
        </Link>
      </div>
    </div>
  )
}

export default HotelBook
