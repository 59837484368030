import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-06.css'

const ERL06 = (props) => {
  return (
    <div className="erl06-container">
      <Helmet>
        <title>快铁</title>
        <meta property="og:title" content="ERL-06 - exported project" />
      </Helmet>
      <div className="erl06-container1">
        <img
          alt="image"
          src="/images/ERL06-express-contact1.svg"
          loading="eager"
          className="erl06-image"
        />
        <Link to="/erl-04" className="erl06-navlink"></Link>
        <Link to="/erl-07" className="erl06-navlink1">
          <img
            alt="image"
            src="/images/ERL06-express-contact2.svg"
            loading="eager"
            className="erl06-image1"
          />
        </Link>
      </div>
    </div>
  )
}

export default ERL06
