import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-01.css'

const Bus01 = (props) => {
  return (
    <div className="bus01-container">
      <Helmet>
        <title>公交</title>
        <meta property="og:title" content="Bus-01 - exported project" />
      </Helmet>
      <div className="bus01-container01">
        <div className="bus01-container02">
          <div className="bus01-container03">
            <Link to="/bus-00-home-page" className="bus01-navlink">
              <img
                alt="image"
                src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/Group 4515.svg"
                className="bus01-image"
              />
            </Link>
            <span className="bus01-text">吉隆坡-槟城</span>
          </div>
        </div>
        <div className="bus01-container04">
          <div className="bus01-container05">
            <img
              alt="image"
              src="/images/Group 4605-1.svg"
              className="bus01-image1"
            />
            <div className="bus01-container06">
              <img
                alt="image"
                src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/calendar_month_FILL0_wght300_GRAD0_opsz48 (2) 1.svg"
                className="bus01-image2"
              />
            </div>
          </div>
        </div>
        <div className="bus01-container07">
          <div className="bus01-container08">
            <Link to="/bus-02" className="bus01-navlink1">
              <img
                alt="image"
                src="/activity/Group 4603-1.svg"
                className="bus01-image3"
              />
            </Link>
          </div>
        </div>
        <div className="bus01-container09">
          <Link to="/bus-10" className="bus01-navlink2">
            <img
              alt="image"
              src="/images/Group 4685.svg"
              className="bus01-image4"
            />
          </Link>
          <Link to="/bus-10" className="bus01-navlink3">
            文本
          </Link>
        </div>
        <div className="bus01-container10"></div>
      </div>
    </div>
  )
}

export default Bus01
