import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './hotel-payment1.css'

const HotelPayment1 = (props) => {
	return (
		<div className="hotel-payment1-container">
			<Helmet>
				<title>HotelPayment1 - exported project</title>
				<meta property="og:title" content="HotelPayment1 - exported project"/>
			</Helmet>
			<div className="hotel-payment1-container1">
				<img
					alt="image"
					src="/activity/hotel-payment1.svg"
					className="hotel-payment1-image"
				/>
				<Link to="/hotel-payment" className="hotel-payment1-navlink">

				</Link>
			</div>
			<Link to="/hotel-receipt" className="hotel-payment1-navlink1">
			</Link>
			{/* <div className="hotel-payment1-container2">
        <Link to="/hotel-receipt" className="hotel-payment1-navlink1">
                
        </Link>
      </div>*/}
		</div>
	)
}

export default HotelPayment1
