import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './my-trip-05.css'

const MyTrip05 = (props) => {
  return (
    <div className="my-trip05-container">
      <Helmet>
        <title>MyTrip-05 - exported project</title>
        <meta property="og:title" content="MyTrip-05 - exported project" />
      </Helmet>
      <div className="my-trip05-container01">
        <div className="my-trip05-container02">
          <Link to="/my-trip-04" className="my-trip05-navlink">
            <img
              alt="image"
              src="/Homepage/back-arrow.svg"
              className="my-trip05-image"
            />
          </Link>
          <h2 className="my-trip05-heading">
            <span>添加酒店</span>
            <br></br>
          </h2>
        </div>
        <div className="my-trip05-container03"></div>
        <div className="my-trip05-container04">
          <span className="my-trip05-text02">
            <span>酒店名称</span>
            <br></br>
          </span>
        </div>
        <div className="my-trip05-container05">
          <span className="my-trip05-text05">登记 &amp; 退房</span>
          <div className="my-trip05-container06">
            <span className="my-trip05-text06">
              <span>26 June - 29 June</span>
              <br></br>
            </span>
            <img
              alt="image"
              src="/mytrip_assets/mytrip5.svg"
              className="my-trip05-image1"
            />
          </div>
        </div>
        <div className="my-trip05-container07"></div>
        <span className="my-trip05-text09">或选择从您购买</span>
        <div className="my-trip05-container08">
          <div className="my-trip05-container09">
            <span className="my-trip05-text10">17 July - 18 July, 2023</span>
            <span className="my-trip05-text11">吉隆坡瑞吉酒店</span>
          </div>
          <input type="radio" name="radio" className="my-trip05-radiobutton" />
        </div>
        <div className="my-trip05-container10"></div>
        <div className="my-trip05-container11">
          <div className="my-trip05-container12">
            <span className="my-trip05-text12">19 July - 20 July, 2023</span>
            <span className="my-trip05-text13">吉隆坡德国之声</span>
          </div>
          <input type="radio" name="radio" className="my-trip05-radiobutton1" />
        </div>
      </div>
      <div className="my-trip05-container13">
        <div className="my-trip05-container14"></div>
        <Link to="/my-trip-06" className="my-trip05-navlink1">
          <img
            alt="image"
            src="https://mycompasscommunication.blob.core.windows.net/temp/mytrip-confirm-btn.svg"
            className="my-trip05-image2"
          />
        </Link>
      </div>
    </div>
  )
}

export default MyTrip05
