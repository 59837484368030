import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './airport-transfer01.css'

const AirportTransfer01 = (props) => {
	return (
		<div className="airport-transfer01-container">
			<Helmet>
				<title>机场接送</title>
				<meta
					property="og:title"
					content="AirportTransfer01 - exported project"
				/>
			</Helmet>
			<Link to="/" className="airport-transfer01-navlink">
				<img
					alt="image"
					src="/airport_transfer/btn-back-200h.png"
					className="airport-transfer01-image"
				/>
			</Link>
			<img
				alt="image"
				src="/airport_transfer/bg-header-1500w.png"
				className="airport-transfer01-image1"
			/>
			<span className="airport-transfer01-text">
				<span>机场接送</span>
				<br></br>
				<br></br>
			</span>
			<span className="airport-transfer01-text04">
				<span>
					它是连接机场和您想要的目的地的重要纽带，确保旅途无压力。
				</span>
				<br></br>
				<br></br>
			</span>
			<div className="airport-transfer01-container1">
				<div className="airport-transfer01-container2">
					<button type="button" className="airport-transfer01-button button">
						<span className="airport-transfer01-text08">
							<span>入境</span>
							<br></br>
						</span>
					</button>
					<button type="button" className="airport-transfer01-button1 button">
						<span className="airport-transfer01-text11">
							<span>离境</span>
							<br></br>
						</span>
					</button>
				</div>
				<input
					type="text"
					placeholder="到达机场"
					className="airport-transfer01-textinput input"
				/>
				<input
					type="text"
					placeholder="去"
					className="airport-transfer01-textinput1 input"
				/>
				<div className="airport-transfer01-container3">
					<input
						type="text"
						value=""
						placeholder="出发日期"
						className="airport-transfer01-textinput2 input"
					/>
					<img
						alt="image"
						src="/airport_transfer/icon-calendar-200h.png"
						className="airport-transfer01-image2"
					/>
				</div>
				<input
					type="text"
					placeholder="选择时间"
					className="airport-transfer01-textinput3 input"
				/>
				<input
					type="text"
					placeholder="乘客人数"
					className="airport-transfer01-textinput4 input"
				/>
				<Link
					to="/airport-transfer02"
					className="airport-transfer01-navlink1 button"
				>
					<span>
						<span>搜索</span>
						<br></br>
					</span>
				</Link>
			</div>
			<div className="airport-transfer01-container4">
				<span className="airport-transfer01-text17">
					如何预订机场接送服务
				</span>
				<div className="airport-transfer01-container5">
					<label className="airport-transfer01-text18">
						<span>01</span>
						<br></br>
						<br></br>
					</label>
					<span className="airport-transfer01-text22">
						填写您的航班目的地和信息
					</span>
				</div>
				<div className="airport-transfer01-container6">
					<label className="airport-transfer01-text23">
						<span>02</span>
						<br></br>
					</label>
					<span className="airport-transfer01-text26">
						<span>比较并预订您的交通</span>
						<br></br>
					</span>
				</div>
				<div className="airport-transfer01-container7">
					<label className="airport-transfer01-text29">
						<span>03</span>
						<br></br>
					</label>
					<span className="airport-transfer01-text32">
						<span>与司机见面</span>
						<br></br>
					</span>
				</div>
				<div className="airport-transfer01-container8">
					<label className="airport-transfer01-text35">
						<span>04</span>
						<br></br>
					</label>
					<span className="airport-transfer01-text38">
						到达目的地
					</span>
				</div>
			</div>
		</div>
	)
}

export default AirportTransfer01
