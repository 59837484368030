import React from 'react'

import { Helmet } from 'react-helmet'

import BottomNavBar from '../components/bottom-nav-bar'
import './my-trip-06.css'

const MyTrip06 = (props) => {
  return (
    <div className="my-trip06-container">
      <Helmet>
        <title>MyTrip-06 - exported project</title>
        <meta property="og:title" content="MyTrip-06 - exported project" />
      </Helmet>
      <h1 className="my-trip06-text">我的旅行</h1>
      <div className="my-trip06-container1">
        <span className="my-trip06-text1">我的假期</span>
        <span className="my-trip06-text2">计划</span>
      </div>
      <button type="button" className="my-trip06-button button">
        +
      </button>
      <BottomNavBar rootClassName="bottom-nav-bar-root-class-name4"></BottomNavBar>
    </div>
  )
}

export default MyTrip06
