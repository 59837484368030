import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './online-shopping.css'

const OnlineShopping = (props) => {
	return (
		<div className="online-shopping-container">
			<Helmet>
				<title>OnlineShopping - exported project</title>
				<meta property="og:title" content="OnlineShopping - exported project"/>
			</Helmet>
			<div className="online-shopping-container1">
				<img
					alt="image"
					src="/activity/uu2 marketplace.svg"
					className="online-shopping-image"
				/>
				<Link to="/extend-explore-page" className="online-shopping-navlink">

				</Link>
			</div>
		</div>
	)
}

export default OnlineShopping
