import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './staycation-02.css'

const Staycation02 = (props) => {
  return (
    <div className="staycation02-container">
      <Helmet>
        <title>Staycation-02 - exported project</title>
        <meta property="og:title" content="Staycation-02 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/activity/Staycations - search state (3).svg"
        className="staycation02-image"
      />
      <Link to="/staycation-01" className="staycation02-navlink">
        Text
      </Link>
      <Link to="/staycation-03" className="staycation02-navlink1">
        Text
      </Link>
    </div>
  )
}

export default Staycation02
