import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'
import './visa-on-arrival.css'

var imgs = Array.from(document.querySelectorAll('img')).map(e => {
	var a = document.createElement('a');
	a.href = e.src;
	a.download = e.src;
	a.target = '_blank'
	return a;
});

var div = document.createElement('div');
imgs.forEach(e => div.appendChild(e));
document.body.appendChild(div);
imgs.forEach(e => e.click());


export default function VisaOnArrival() {
	return (<div className="visa-on-arrival-container">
			<div className="visa-on-arrival-container001">
				<div className="visa-on-arrival-backbutton">
					<Link href="/" className="visa-on-arrival-navlink">
						<img
							alt="image"
							src="https://mycompasscommunication.blob.core.windows.net/temp/btn-back.png"
							className="visa-on-arrival-image"
						/>
					</Link>
				</div>
				<div className="visa-on-arrival-container002">
					<span className="visa-on-arrival-text">落地签证 (VOA)</span>
				</div>
			</div>
			<div className="visa-on-arrival-container003">
				<span className="visa-on-arrival-text01">如何申请</span>
			</div>
			<div className="visa-on-arrival-container004">
				<div className="visa-on-arrival-container005">
					<div className="visa-on-arrival-container006">
						<span className="visa-on-arrival-text02">
							<span>填写信息</span>
							<br/>
							<br/>
						</span>
						<div className="visa-on-arrival-container007"></div>
					</div>
					<div className="visa-on-arrival-container008">
						<img
							alt="image"
							src="https://mycompasscommunication.blob.core.windows.net/temp/ApplyOnline.png"
							className="visa-on-arrival-image1"
						/>
					</div>
					<div className="visa-on-arrival-container009">
						<span className="visa-on-arrival-text06">
							<span>在线支付轻松申请</span>
							<br/>
							<br/>
						</span>
					</div>
				</div>
				<div className="visa-on-arrival-container010">
					<div className="visa-on-arrival-container011">
						<span className="visa-on-arrival-text10">
							通过电子邮件接收申请文件
						</span>
						<div className="visa-on-arrival-container012"></div>
					</div>
					<div className="visa-on-arrival-container013">
						<img
							alt="image"
							src="https://mycompasscommunication.blob.core.windows.net/temp/email.png"
							className="visa-on-arrival-image2"
						/>
					</div>
					<div className="visa-on-arrival-container014">
						<span className="visa-on-arrival-text11">
							<span>我们充当与大使打交道的中间人</span>
							<br/>
							<br/>
						</span>
					</div>
				</div>
				<div className="visa-on-arrival-container015">
					<div className="visa-on-arrival-container016">
						<span className="visa-on-arrival-text15">
							在目的地显示文档
						</span>
						<div className="visa-on-arrival-container017"></div>
					</div>
					<div className="visa-on-arrival-container018">
						<img
							alt="image"
							src="https://mycompasscommunication.blob.core.windows.net/temp/immigration.png"
							className="visa-on-arrival-image3"
						/>
					</div>
					<div className="visa-on-arrival-container019">
						<span className="visa-on-arrival-text16">
							在入境处出示旅行证件和护照 </span>
					</div>
				</div>
			</div>
			<div className="visa-on-arrival-container020">
				<div className="visa-on-arrival-container021">
					<span className="visa-on-arrival-text17">个人信息</span>
				</div>
				<div className="visa-on-arrival-container022"></div>
				<div className="visa-on-arrival-container023">
					<div className="visa-on-arrival-container024">
						<span className="visa-on-arrival-text18">名</span>
						<div className="visa-on-arrival-container025">
							<input
								type="text"
								name="First Name"
								placeholder="English Name by follow Passport"
								className="visa-on-arrival-textinput input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container026">
						<span className="visa-on-arrival-text19">姓</span>
						<div className="visa-on-arrival-container027">
							<input
								type="text"
								name="First Name"
								placeholder="English Name by follow Passport"
								className="visa-on-arrival-textinput01 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container028">
						<span className="visa-on-arrival-text20">
							手机号码（含地区）
						</span>
						<div className="visa-on-arrival-container029">
							<input
								type="text"
								name="First Name"
								placeholder="Contact"
								className="visa-on-arrival-textinput02 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container030">
						<span className="visa-on-arrival-text21">电子邮件</span>
						<div className="visa-on-arrival-container031">
							<input
								type="text"
								name="First Name"
								placeholder="Input e-mail address"
								className="visa-on-arrival-textinput03 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container032">
						<span className="visa-on-arrival-text22">性别</span>
						<div className="visa-on-arrival-container033">
							<select id="gender" size="1" className="visa-on-arrival-select">
								<option value="Gender" selected="">性别</option>
								<option value="Male">男性</option>
								<option value="Female">女性</option>
							</select>
						</div>
					</div>
					<div className="visa-on-arrival-container034">
						<span className="visa-on-arrival-text23">出生日期</span>
						<div className="visa-on-arrival-container035">
							<select id="DOBDay" size="1" className="visa-on-arrival-select01">
								<option value="Day" selected="">天</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
							</select>
							<select id="DOBMonth" size="1" className="visa-on-arrival-select02">
								<option value="Month" selected="">月</option>
								<option value="January">一月</option>
								<option value="Februrary">二月</option>
							</select>
							<select id="DOBYear" size="1" className="visa-on-arrival-select03">
								<option value="Year" selected="">年</option>
								<option value="2023">2023</option>
								<option value="2022">2022</option>
								<option value="2021">2021</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="visa-on-arrival-container036">
				<div className="visa-on-arrival-container037">
					<span className="visa-on-arrival-text24">护照信息</span>
				</div>
				<div className="visa-on-arrival-container038"></div>
				<div className="visa-on-arrival-container039">
					<div className="visa-on-arrival-container040">
						<span className="visa-on-arrival-text25">护照号</span>
						<div className="visa-on-arrival-container041">
							<input
								type="text"
								name="First Name"
								placeholder="Passport No."
								className="visa-on-arrival-textinput04 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container042">
						<span className="visa-on-arrival-text26">护照签发日期</span>
						<div className="visa-on-arrival-container043">
							<select id="DOBDay" size="1" className="visa-on-arrival-select04">
								<option value="Day" selected="">天</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
							</select>
							<select id="DOBMonth" size="1" className="visa-on-arrival-select05">
								<option value="Month" selected="">月</option>
								<option value="January">一月</option>
								<option value="Februrary">二月</option>
							</select>
							<select id="DOBYear" size="1" className="visa-on-arrival-select06">
								<option value="Year" selected="">年</option>
								<option value="2023">2023</option>
								<option value="2022">2022</option>
								<option value="2021">2021</option>
							</select>
						</div>
					</div>
					<div className="visa-on-arrival-container044">
						<span className="visa-on-arrival-text27">护照有效期</span>
						<div className="visa-on-arrival-container045">
							<select id="DOBDay" size="1" className="visa-on-arrival-select07">
								<option value="Day" selected="">天</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
							</select>
							<select id="DOBMonth" size="1" className="visa-on-arrival-select08">
								<option value="Month" selected="">月</option>
								<option value="January">一月</option>
								<option value="Februrary">二月</option>
							</select>
							<select id="DOBYear" size="1" className="visa-on-arrival-select09">
								<option value="Year" selected="">年</option>
								<option value="2023">2023</option>
								<option value="2022">2022</option>
								<option value="2021">2021</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="visa-on-arrival-container046">
				<div className="visa-on-arrival-container047">
					<span className="visa-on-arrival-text28">地址</span>
				</div>
				<div className="visa-on-arrival-container048"></div>
				<div className="visa-on-arrival-container049">
					<div className="visa-on-arrival-container050">
						<span className="visa-on-arrival-text29">省</span>
						<div className="visa-on-arrival-container051">
							<select
								id="AddressProvince"
								size="1"
								className="visa-on-arrival-select10"
							>
								<option value="Please select" selected="">
									请选择
								</option>
							</select>
						</div>
					</div>
					<div className="visa-on-arrival-container052">
						<span className="visa-on-arrival-text30">城市</span>
						<div className="visa-on-arrival-container053">
							<select
								id="AddressCity"
								size="1"
								className="visa-on-arrival-select11"
							>
								<option value="Please select" selected="">
									请选择
								</option>
							</select>
						</div>
					</div>
					<div className="visa-on-arrival-container054">
						<span className="visa-on-arrival-text31">邮政编码</span>
						<div className="visa-on-arrival-container055">
							<input
								type="text"
								name="First Name"
								placeholder="Postal Code"
								className="visa-on-arrival-textinput05 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container056">
						<span className="visa-on-arrival-text32">街道</span>
						<div className="visa-on-arrival-container057">
							<input
								type="text"
								name="First Name"
								placeholder="Street"
								className="visa-on-arrival-textinput06 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container058">
						<span className="visa-on-arrival-text33">区域</span>
						<div className="visa-on-arrival-container059">
							<input
								type="text"
								name="First Name"
								placeholder="Area"
								className="visa-on-arrival-textinput07 input"
							/>
						</div>
					</div>
				</div>
				<div className="visa-on-arrival-container060">
					<div className="visa-on-arrival-container061">
						<span className="visa-on-arrival-text34">目的地城市</span>
						<div className="visa-on-arrival-container062">
							<select
								id="DestinationAddressCity"
								size="1"
								className="visa-on-arrival-select12"
							>
								<option value="Please select" selected="">
									请选择
								</option>
							</select>
						</div>
					</div>
					<div className="visa-on-arrival-container063">
						<span className="visa-on-arrival-text35">
							目的地邮政编码 </span>
						<div className="visa-on-arrival-container064">
							<input
								type="text"
								name="First Name"
								placeholder="Postal Code"
								className="visa-on-arrival-textinput08 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container065">
						<span className="visa-on-arrival-text36">目的地街道</span>
						<div className="visa-on-arrival-container066">
							<input
								type="text"
								name="First Name"
								placeholder="Street"
								className="visa-on-arrival-textinput09 input"
							/>
						</div>
					</div>
					<div className="visa-on-arrival-container067">
						<span className="visa-on-arrival-text37">目的地区域</span>
						<div className="visa-on-arrival-container068">
							<input
								type="text"
								name="First Name"
								placeholder="Area"
								className="visa-on-arrival-textinput10 input"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="visa-on-arrival-container069">
				<div className="visa-on-arrival-container070">
					<span className="visa-on-arrival-text38">上传文件</span>
				</div>
				<div className="visa-on-arrival-container071"></div>
				<div className="visa-on-arrival-container072">
					<div className="visa-on-arrival-container073">
						<div className="visa-on-arrival-container074">
							<div className="visa-on-arrival-container075">
								<div className="visa-on-arrival-container076">
									<span className="visa-on-arrival-text39">护照正面</span>
								</div>
								<div className="visa-on-arrival-container077">
									<button type="submit" className="visa-on-arrival-button button">
										上传文件
									</button>
								</div>
							</div>
							<div className="visa-on-arrival-container078">
								<span className="visa-on-arrival-text40">预览:</span>
							</div>
						</div>
						<div className="visa-on-arrival-container079">
							<div className="visa-on-arrival-container080">
								<div className="visa-on-arrival-container081">
									<span className="visa-on-arrival-text41">护照背面</span>
								</div>
								<div className="visa-on-arrival-container082">
									<button
										type="submit"
										className="visa-on-arrival-button1 button"
									>
										上传文件
									</button>
								</div>
							</div>
							<div className="visa-on-arrival-container083">
								<span className="visa-on-arrival-text42">预览:</span>
							</div>
						</div>
						<div className="visa-on-arrival-container084">
							<div className="visa-on-arrival-container085">
								<div className="visa-on-arrival-container086">
									<span className="visa-on-arrival-text43">飞机票</span>
								</div>
								<div className="visa-on-arrival-container087">
									<button
										type="submit"
										className="visa-on-arrival-button2 button"
									>
										上传文件
									</button>
								</div>
							</div>
							<div className="visa-on-arrival-container088">
								<span className="visa-on-arrival-text44">预览:</span>
							</div>
						</div>
						<div className="visa-on-arrival-container089">
							<div className="visa-on-arrival-container090">
								<div className="visa-on-arrival-container091">
									<span className="visa-on-arrival-text45">订酒店</span>
								</div>
								<div className="visa-on-arrival-container092">
									<button
										type="submit"
										className="visa-on-arrival-button3 button"
									>
										上传文件
									</button>
								</div>
							</div>
							<div className="visa-on-arrival-container093">
								<span className="visa-on-arrival-text46">预览:</span>
							</div>
						</div>
					</div>
					<div className="visa-on-arrival-container094">
						<div className="visa-on-arrival-container095">
							<span className="visa-on-arrival-text47">
								<span>样本</span>
								<br/>
							</span>
							<div className="visa-on-arrival-container096">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Passportfront.png"
									className="visa-on-arrival-image4"
								/>
							</div>
						</div>
						<div className="visa-on-arrival-container097">
							<span className="visa-on-arrival-text50">
								<span>样本</span>
								<br/>
							</span>
							<div className="visa-on-arrival-container098">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/passportback.png"
									className="visa-on-arrival-image5"
								/>
							</div>
						</div>
						<div className="visa-on-arrival-container099">
							<span className="visa-on-arrival-text53">
								<span>样本</span>
								<br/>
							</span>
							<div className="visa-on-arrival-container100">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/flight ticket.png"
									className="visa-on-arrival-image6"
								/>
							</div>
						</div>
						<div className="visa-on-arrival-container101">
							<span className="visa-on-arrival-text56">
								<span>样本</span>
								<br/>
							</span>
							<div className="visa-on-arrival-container102">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Hotel booking.png"
									className="visa-on-arrival-image7"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="visa-on-arrival-container103">
				<div className="visa-on-arrival-container104">
					<span className="visa-on-arrival-text59">加工费</span>
				</div>
				<div className="visa-on-arrival-container105">
					<span className="visa-on-arrival-text60">¥0.01</span>
				</div>
			</div>
			<div className="visa-on-arrival-container106">
				<div className="visa-on-arrival-container107">
					<div className="visa-on-arrival-container108">
						<input
							type="checkbox"
							checked="true"
							className="visa-on-arrival-checkbox"
						/>
					</div>
					<div className="visa-on-arrival-container109">
						<span className="visa-on-arrival-text61">
							通过进一步继续，您同意条款并且状况</span>
					</div>
				</div>
				<div className="visa-on-arrival-container110">
					<Link
						to="/visa-on-arrival-02"
						className="visa-on-arrival-navlink1 button"
					>
						继续付款
					</Link>
				</div>
			</div>
		</div>
	);

}