import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-07.css'

const MeetGreet07 = (props) => {
  return (
    <div className="meet-greet07-container">
      <Helmet>
        <title>见面会</title>
        <meta property="og:title" content="Meet-Greet-07 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/images/Meet & Greet - completed.svg"
        className="meet-greet07-image"
      />
      <div className="meet-greet07-container1">
        <Link to="/meet-greet-07" className="meet-greet07-navlink">
          <img
            alt="image"
            src="/images/Meet&Greet12.svg"
            className="meet-greet07-image1"
          />
        </Link>
        <Link to="/" className="meet-greet07-navlink1">
          <img
            alt="image"
            src="/images/Meet&Greet13.svg"
            className="meet-greet07-image2"
          />
        </Link>
      </div>
    </div>
  )
}

export default MeetGreet07
