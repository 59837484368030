import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-10.css'

const Bus10 = (props) => {
  return (
    <div className="bus10-container">
      <Helmet>
        <title>Bus-10 - exported project</title>
        <meta property="og:title" content="Bus-10 - exported project" />
      </Helmet>
      <div className="bus10-container01">
        <div className="bus10-container02">
          <div className="bus10-container03">
            <Link to="/bus-00-home-page" className="bus10-navlink">
              <img
                alt="image"
                src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/Group 4515.svg"
                className="bus10-image"
              />
            </Link>
            <span className="bus10-text">吉隆坡飞往槟城的航班</span>
          </div>
        </div>
        <div className="bus10-container04">
          <div className="bus10-container05">
            <img
              alt="image"
              src="/activity/Group 4605-1.svg"
              className="bus10-image1"
            />
            <div className="bus10-container06">
              <img
                alt="image"
                src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/calendar_month_FILL0_wght300_GRAD0_opsz48 (2) 1.svg"
                className="bus10-image2"
              />
            </div>
          </div>
        </div>
        <div className="bus10-container07">
          <div className="bus10-container08">
            <Link to="/bus-02" className="bus10-navlink1">
              <img
                alt="image"
                src="/activity/Group 4603-1.svg"
                className="bus10-image3"
              />
            </Link>
          </div>
        </div>
        <Link to="/bus-01" className="bus10-navlink2">
          <div className="bus10-container09"></div>
        </Link>
        <Link to="/bus-01" className="bus10-navlink3">
          <img
            alt="image"
            src="/activity/Filters.svg"
            className="bus10-image4"
          />
        </Link>
        <span className="bus10-text1">文本</span>
      </div>
    </div>
  )
}

export default Bus10
