import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-04.css'

const Bus04 = (props) => {
  return (
    <div className="bus04-container">
      <Helmet>
        <title>公交</title>
        <meta property="og:title" content="Bus-04 - exported project" />
      </Helmet>
      <div className="bus04-container1">
        <img
          alt="image"
          src="/images/Buses - add new passenger.svg"
          loading="eager"
          className="bus04-image"
        />
      </div>
      <Link to="/bus-03" className="bus04-navlink">
        文本
      </Link>
    </div>
  )
}

export default Bus04
