import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './flight-status-03.css'

const FlightStatus03 = (props) => {
  return (
    <div className="flight-status03-container">
      <Helmet>
        <title>航班</title>
        <meta
          property="og:title"
          content="Flight-Status-03 - exported project"
        />
      </Helmet>
      <Link to="/flight-status-04" className="flight-status03-navlink">
        <svg viewBox="0 0 1024 1024" className="flight-status03-icon">
          <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
        </svg>
      </Link>
      <span className="flight-status03-text">
        <span>AK1521</span>
        <br></br>
      </span>
      <svg viewBox="0 0 1024 1024" className="flight-status03-icon2">
        <path d="M768 686q52 0 88 37t36 87q0 52-37 89t-87 37-87-37-37-89q0-20 2-28l-302-176q-38 34-88 34-52 0-90-38t-38-90 38-90 90-38q50 0 88 34l300-174q-4-20-4-30 0-52 38-90t90-38 90 38 38 90-38 90-90 38q-48 0-88-36l-300 176q4 20 4 30t-4 30l304 176q36-32 84-32z"></path>
      </svg>
      <img
        alt="image"
        src="https://mycompasscommunication.blob.core.windows.net/temp/FlightStatus1.svg"
        className="flight-status03-image"
      />
      <img
        alt="image"
        src="/images/FlightStatus3.svg"
        className="flight-status03-image1"
      />
      <div className="flight-status03-container1"></div>
      <div className="flight-status03-container2">
        <Link to="/flight-status-02" className="flight-status03-navlink1">
          <img
            alt="image"
            src="/images/FlightStatus7.svg"
            className="flight-status03-image2"
          />
        </Link>
      </div>
    </div>
  )
}

export default FlightStatus03
