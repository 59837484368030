import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ehailing-01.css'

const Ehailing01 = (props) => {
  return (
    <div className="ehailing01-container">
      <Helmet>
        <title>Ehailing-01 - exported project</title>
        <meta property="og:title" content="Ehailing-01 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/activity/E-hailing(1).svg"
        className="ehailing01-image"
      />
      <Link to="/" className="ehailing01-navlink">
        文本
      </Link>
      <Link to="/ehailing-02" className="ehailing01-navlink1">
        文本
      </Link>
    </div>
  )
}

export default Ehailing01
