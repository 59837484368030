import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './my-trip-03.css'

const MyTrip03 = (props) => {
  return (
    <div className="my-trip03-container">
      <Helmet>
        <title>我的旅行</title>
        <meta property="og:title" content="MyTrip-03 - exported project" />
      </Helmet>
      <div className="my-trip03-container1">
        <div className="my-trip03-container2">
          <Link to="/my-trip-02" className="my-trip03-navlink">
            <img
              alt="image"
              src="/Homepage/back-arrow.svg"
              className="my-trip03-image"
            />
          </Link>
          <h2 className="my-trip03-heading">
            <span>添加酒店</span>
            <br></br>
          </h2>
        </div>
        <div className="my-trip03-container3"></div>
        <h2 className="my-trip03-text2">
          <span>增添</span>
          <br></br>
        </h2>
        <div className="my-trip03-container4">
          <span className="my-trip03-text5">新行程</span>
          <input type="radio" name="radio" className="my-trip03-radiobutton" />
        </div>
      </div>
      <div className="my-trip03-container5">
        <div className="my-trip03-container6"></div>
        <Link to="/my-trip-04" className="my-trip03-navlink1">
          <img
            alt="image"
            src="/images/mytrip-next-btn.svg"
            className="my-trip03-image1"
          />
        </Link>
      </div>
    </div>
  )
}

export default MyTrip03
