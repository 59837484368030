import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './activity-04.css'

const Activity04 = (props) => {
  return (
    <div className="activity04-container">
      <Helmet>
        <title>Activity-04 - exported project</title>
        <meta property="og:title" content="Activity-04 - exported project" />
      </Helmet>
      <img
        alt="pastedImage"
        src="/activity/activity04.svg"
        loading="eager"
        className="activity04-pasted-image"
      />
      <div className="activity04-container1">
        <button type="button" className="activity04-receipt-button button">
          <Link to="/activity-05" className="activity04-navlink">
            <span>Show Ticket</span>
            <br></br>
          </Link>
        </button>
      </div>
      <Link to="/activity-01" className="activity04-navlink1">
        Link
      </Link>
    </div>
  )
}

export default Activity04
