import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './-hailing-05.css'

const EHailing05 = (props) => {
  return (
    <div className="-hailing05-container">
      <Helmet>
        <title>电召车</title>
        <meta property="og:title" content="EHailing-05 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/images/E-hailing select location (12).svg"
        className="-hailing05-image"
      />
      <Link to="/ehailing-04" className="-hailing05-navlink">
        文本
      </Link>
      <Link to="/ehailing-06" className="-hailing05-navlink1">
      文本
      </Link>
    </div>
  )
}

export default EHailing05
