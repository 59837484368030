import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './extend-explore-page.css'

const ExtendExplorePage = (props) => {
	return (
		<div className="extend-explore-page-container">
			<Helmet>
				<title>扩展探索页面 - exported project</title>
				<meta
					property="og:title"
					content="ExtendExplorePage - exported project"
				/>
			</Helmet>
			<div className="extend-explore-page-container01">
				<Link to="/my-trip-01" className="extend-explore-page-navlink">
					<img
						alt="image"
						src="/Homepage/black-square-for-overlay.svg"
						className="extend-explore-page-image"
					/>
				</Link>
				<div className="extend-explore-page-container02">
					<img
						alt="image"
						src="/Homepage/mailbox-200h.png"
						className="extend-explore-page-notification"
					/>
					<img
						alt="image"
						src="/Homepage/homepagescreen%20image-1500w.jpg"
						className="extend-explore-page-homepagebackground"
					/>
					<div className="extend-explore-page-top-bottom-section">
						<span className="extend-explore-page-text">没有即将举行的活动</span>
						<span className="extend-explore-page-text01">你好，Jason Ng</span>
					</div>
					<div className="extend-explore-page-voucher">
						<div className="extend-explore-page-voucher-section">
							<div className="extend-explore-page-container03">
								<img
									alt="image"
									src="/Homepage/gift-200h.png"
									className="extend-explore-page-image01"
								/>
							</div>
							<div className="extend-explore-page-container04">
								<span className="extend-explore-page-text02">
									<span>首次预订可享八五折优惠。</span>
									<br></br>
								</span>
								<span className="extend-explore-page-text05">查看优惠券</span>
							</div>
						</div>
					</div>
					<span className="extend-explore-page-text06">
						发现并计划您的下一次旅程
					</span>
				</div>
			</div>
			<div className="extend-explore-page-extend">
				<div className="extend-explore-page-container05">
					<h4 className="extend-explore-page-place-to-stay">Places to Stay</h4>
					<div className="extend-explore-page-places-to-stay">
						<Link to="/hotel" className="extend-explore-page-navlink01">
							<div className="extend-explore-page-container06">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Hotels.png"
									className="extend-explore-page-image02"
								/>
								<span className="extend-explore-page-hotels">酒店</span>
							</div>
						</Link>
						<Link to="/staycation-01" className="extend-explore-page-navlink02">
							<div className="extend-explore-page-container07">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Staycations.png"
									className="extend-explore-page-image03"
								/>
								<span className="extend-explore-page-text07">
									<span>度假</span>
									<br></br>
								</span>
							</div>
						</Link>
					</div>
				</div>
				<div className="extend-explore-page-container08">
					<h4 className="extend-explore-page-travel">
						旅游 &amp; 体验
					</h4>
					<div className="extend-explore-page-travel-experiences">
						<div className="extend-explore-page-container09">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/Attraction.png"
								className="extend-explore-page-image04"
							/>
							<span className="extend-explore-page-attractions">
								<span>景点</span>
								<br></br>
							</span>
						</div>
						<div className="extend-explore-page-container10">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/Events &amp; Exhibitions.png"
								className="extend-explore-page-image05"
							/>
							<span className="extend-explore-page-events-exhibitions">
								<span>活动 &amp;</span>
								<br></br>
								<span>展览</span>
							</span>
						</div>
						<div className="extend-explore-page-container11">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/water.png"
								className="extend-explore-page-image06"
							/>
							<span className="extend-explore-page-water-activity">
								<span>水域</span>
								<br></br>
								<span>活动</span>
								<br></br>
							</span>
						</div>
						<div className="extend-explore-page-container12">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/Experience.png"
								className="extend-explore-page-image07"
							/>
							<span className="extend-explore-page-experience">经验</span>
						</div>
					</div>
				</div>
				<div className="extend-explore-page-container13">
					<h4 className="extend-explore-page-others">
						<span>其他</span>
						<br></br>
					</h4>
					<div className="extend-explore-page-others1">
						<Link to="/duty-free-01" className="extend-explore-page-navlink03">
							<div className="extend-explore-page-container14">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Duty Free.png"
									className="extend-explore-page-image08"
								/>
								<span className="extend-explore-page-duty-free">
									<span>免税</span>
									<br></br>
								</span>
							</div>
						</Link>
						<div className="extend-explore-page-container15">
							<Link
								to="/online-shopping"
								className="extend-explore-page-navlink04"
							>
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Online Shop.png"
									className="extend-explore-page-image09"
								/>
							</Link>
							<span className="extend-explore-page-online-shopping">
								<span>网上购物</span>
								<br></br>
							</span>
						</div>
					</div>
				</div>
				<div className="extend-explore-page-container16">
					<h4 className="extend-explore-page-airport">
						<span>机场服务</span>
						<br></br>
					</h4>
					<div className="extend-explore-page-airport-services">
						<Link
							to="/flight-status-01"
							className="extend-explore-page-navlink05"
						>
							<div className="extend-explore-page-container17">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Flight status.png"
									className="extend-explore-page-image10"
								/>
								<span className="extend-explore-page-hotels01">
									航班状态
								</span>
							</div>
						</Link>
						<Link to="/meet-greet-01" className="extend-explore-page-navlink06">
							<div className="extend-explore-page-container18">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Meet &amp; Greet.png"
									className="extend-explore-page-image11"
								/>
								<span className="extend-explore-page-hotels02">
									<span>见面会</span>
									<br></br>
								</span>
							</div>
						</Link>
						<Link to="/directory-map" className="extend-explore-page-navlink07">
							<div className="extend-explore-page-container19">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Directory Map.png"
									className="extend-explore-page-image12"
								/>
								<span className="extend-explore-page-hotels03">
									<span>目录图</span>
									<br></br>
								</span>
							</div>
						</Link>
						<div className="extend-explore-page-container20">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/WheeChair Service.png"
								className="extend-explore-page-image13"
							/>
							<span className="extend-explore-page-hotels04">
								<span>轮椅服务</span>
								<br></br>
							</span>
						</div>
					</div>
				</div>
				<div className="extend-explore-page-container21">
					<h4 className="extend-explore-page-airport1">
						<span>交通运输</span>
						<br></br>
					</h4>
					<div className="extend-explore-page-transportation">
						<Link
							to="/bus-00-home-page"
							className="extend-explore-page-navlink08"
						>
							<div className="extend-explore-page-container22">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Bus.png"
									className="extend-explore-page-image14"
								/>
								<span className="extend-explore-page-hotels05">公共汽车</span>
							</div>
						</Link>
						<Link to="/erl-01" className="extend-explore-page-navlink09">
							<div className="extend-explore-page-container23">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/ERL.png"
									className="extend-explore-page-image15"
								/>
								<span className="extend-explore-page-hotels06">
									<span>高铁</span>
									<br></br>
								</span>
							</div>
						</Link>
						<Link
							to="/airport-transfer01"
							className="extend-explore-page-navlink10"
						>
							<div className="extend-explore-page-container24">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Airport Transfer.png"
									className="extend-explore-page-image16"
								/>
								<span className="extend-explore-page-hotels07">
									<span>机场接送</span>
									<br></br>
								</span>
							</div>
						</Link>
						<div className="extend-explore-page-container25">
							<Link to="/ehailing-01" className="extend-explore-page-navlink11">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/E-hailing.png"
									className="extend-explore-page-image17"
								/>
							</Link>
							<span className="extend-explore-page-hotels08">
								<span>呼叫或租赁</span>
								<br></br>
							</span>
						</div>
					</div>
				</div>
				<div className="extend-explore-page-container26">
					<h4 className="extend-explore-page-airport2">
						<span>欢迎马来西亚 - 签证</span>
						<br></br>
					</h4>
					<div className="extend-explore-page-transportation1">
						<div className="extend-explore-page-container27">
							<Link to="/visa-on-arrival" className="extend-explore-page-navlink11">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/VOA.png"
									className="extend-explore-page-image18"
								/>
							</Link>

							<span className="extend-explore-page-hotels09">
								落地签
							</span>
						</div>
						<div className="extend-explore-page-container28">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/PVIP.png"
								className="extend-explore-page-image19"
							/>
							{/*<Link to="/visa-on-arrival-02" className="extend-explore-page-navlink11">
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/PVIP.png"
									className="extend-explore-page-image19"
								/>
							</Link>*/}
							<span className="extend-explore-page-hotels10">
								<span>高级签证</span>
								<br></br>
							</span>
						</div>
					</div>
				</div>
				<div className="extend-explore-page-container29">
					<Link to="/" className="extend-explore-page-navlink12">
						<img
							alt="image"
							src="https://mycompasscommunication.blob.core.windows.net/temp/Close_Crossicon.svg"
							className="extend-explore-page-image20"
						/>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default ExtendExplorePage
