import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hotel-search2.css'

const HotelSearch2 = (props) => {
  return (
    <div className="hotel-search2-container">
      <Helmet>
        <title>酒店搜索</title>
        <meta property="og:title" content="HotelSearch2 - exported project" />
      </Helmet>
      <div className="hotel-search2-container1">
        <img
          alt="image"
          src="/images/Hotel_Search2.svg"
          className="hotel-search2-image"
        />
        <Link to="/hotel" className="hotel-search2-navlink">
                
        </Link>
        <Link to="/hotel-product" className="hotel-search2-navlink1">
                
        </Link>
      </div>
    </div>
  )
}

export default HotelSearch2
