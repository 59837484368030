import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './airport-transfer05.css'

const AirportTransfer05 = (props) => {
  return (
    <div className="airport-transfer05-container">
      <Helmet>
        <title>机场接送</title>
        <meta
          property="og:title"
          content="AirportTransfer05 - exported project"
        />
      </Helmet>
      <img
        alt="image"
        src="/images/airport-transfer-05.svg"
        className="airport-transfer05-image"
      />
      <Link to="/airport-transfer04" className="airport-transfer05-navlink">
        文本
      </Link>
      <Link to="/airport-transfer06" className="airport-transfer05-navlink1">
      文本
      </Link>
    </div>
  )
}

export default AirportTransfer05
