import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './bus-00-home-page.css'

const Bus00HomePage = (props) => {
	return (
		<div className="bus0-home-page-container">
			<Helmet>
				<title>Bus-00-HomePage - exported project</title>
				<meta
					property="og:title"
					content="Bus-00-HomePage - exported project"
				/>
			</Helmet>
			<div className="bus0-home-page-container01">
				<div className="bus0-home-page-container02">
					<div className="bus0-home-page-container03">
						<Link to="/" className="bus0-home-page-navlink">
							<img
								alt="image"
								src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/Group 4515.svg"
								className="bus0-home-page-image"
							/>
						</Link>
						<span className="bus0-home-page-text">运输</span>
					</div>
				</div>
				<div className="bus0-home-page-container04">
					<button
						type="button"
						className="bus0-home-page-button nav-button button"
					>
						公共汽车
					</button>
					<button
						type="button"
						className="bus0-home-page-button1 nav-button button"
					>
						高铁
					</button>
					<button
						type="button"
						className="bus0-home-page-button2 nav-button button"
					>
						机场接送
					</button>
				</div>
				<div className="bus0-home-page-container05">
					<span className="bus0-home-page-text01">
						<span>我们提供可靠和方便的选择</span>
						<br></br>
						<span>用于通勤</span>
						<br></br>
						<br></br>
					</span>
					<img
						alt="image"
						src="/bus_assets/rectangle%20775.svg"
						className="bus0-home-page-image1"
					/>
				</div>
				<div className="bus0-home-page-container06">
					<div className="bus0-home-page-container07">
						<span className="bus0-home-page-text07">
							<span>出发地</span>
							<br></br>
						</span>
					</div>
					<div className="bus0-home-page-container08">
						<span className="bus0-home-page-text10">
							<span>目的地</span>
							<br></br>
						</span>
					</div>
					<div className="bus0-home-page-container09">
						<div className="bus0-home-page-container10">
							<div className="bus0-home-page-container11">
								<span className="bus0-home-page-text13">
									<span>出发日期</span>
									<br></br>
								</span>
								<span className="bus0-home-page-text16">
									<span>6 月 26 日，星期三</span>
									<br></br>
								</span>
							</div>
						</div>
						<div className="bus0-home-page-container12">
							<div className="bus0-home-page-container13">
								<span className="bus0-home-page-text19">
									<span>添加返回</span>
									<br></br>
								</span>
							</div>
						</div>
					</div>
					<div className="bus0-home-page-container14">
						<Link to="/bus-01" className="bus0-home-page-navlink1 button">
							搜索
						</Link>
					</div>
				</div>
				<Link to="/bus-09" className="bus0-home-page-navlink2">
					<div className="bus0-home-page-container15">
						<div className="bus0-home-page-container16">
							<div className="bus0-home-page-container17">
								<div className="bus0-home-page-container18"></div>
								<img
									alt="image"
									src="https://mycompasscommunication.blob.core.windows.net/temp/Buses/Group 4690.svg"
									className="bus0-home-page-image2"
								/>
							</div>
							<div className="bus0-home-page-container19">
								<span className="bus0-home-page-text22">
									<span>FAQs</span>
									<br></br>
								</span>
								<span className="bus0-home-page-text25">
									<span>获取有关接机票等的更多详细信息。</span>
									<br></br>
								</span>
							</div>
						</div>
					</div>
				</Link>
				<div className="bus0-home-page-container20"></div>
				<div className="bus0-home-page-container21">
					<span className="bus0-home-page-text28">
						<span>热门航线</span>
						<br></br>
					</span>
				</div>
				<div className="bus0-home-page-container22">
					<img
						alt="image"
						src="/activity/Group 4601.svg"
						className="bus0-home-page-image3"
					/>
					<img
						alt="image"
						src="/activity/Group 4602.svg"
						className="bus0-home-page-image4"
					/>
					<img
						alt="image"
						src="/activity/Group 4603.svg"
						className="bus0-home-page-image5"
					/>
					<img
						alt="image"
						src="/activity/Group 4604.svg"
						className="bus0-home-page-image6"
					/>
					<img
						alt="image"
						src="/activity/Group 4605.svg"
						className="bus0-home-page-image7"
					/>
				</div>
			</div>
		</div>
	)
}

export default Bus00HomePage
