import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import BottomNavBar from '../components/bottom-nav-bar'
import './my-trip-01.css'

const MyTrip01 = (props) => {
  return (
    <div className="my-trip01-container">
      <Helmet>
        <title>我的旅行</title>
        <meta property="og:title" content="MyTrip-01 - exported project" />
      </Helmet>
      <h1 className="my-trip01-text">我的旅行</h1>
      <img
        alt="image"
        src="/mytrip_assets/mytrip1.svg"
        className="my-trip01-image"
      />
      <h3 className="my-trip01-text1">计划和组织您的旅行</h3>
      <span className="my-trip01-text2">
      通知您的旅行计划并自动导入智能行程。
      </span>
      <Link to="/my-trip-02" className="my-trip01-navlink button">
        现在加入 →
      </Link>
      <BottomNavBar rootClassName="bottom-nav-bar-root-class-name2"></BottomNavBar>
    </div>
  )
}

export default MyTrip01
