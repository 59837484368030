import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Route} from 'react-router-dom'

import './style.css'
import DutyFree02 from './views/duty-free-02'
import ERL03 from './views/erl-03'
import DutyFree03 from './views/duty-free-03'
import Staycation01 from './views/staycation-01'
import FlightStatus01 from './views/flight-status-01'
import HotelPayment from './views/hotel-payment'
import Bus02 from './views/bus-02'
import AirportTransfer05 from './views/airport-transfer05'
import MeetGreet07 from './views/meet-greet-07'
import HotelSearch2 from './views/hotel-search2'
import MyTrip03 from './views/my-trip-03'
import MeetGreet01 from './views/meet-greet-01'
import Hotel from './views/hotel'
import MeetGreet02 from './views/meet-greet-02'
import ERL06 from './views/erl-06'
import ERL08 from './views/erl-08'
import Ehailing02 from './views/ehailing-02'
import MeetGreet09Edit from './views/meet-greet-09-edit'
import DutyFree021 from './views/duty-free-021'
import Bus08 from './views/bus-08'
import DutyFree01 from './views/duty-free-01'
import EHailing05 from './views/-hailing-05'
import ERL04 from './views/erl-04'
import Ehailing06 from './views/ehailing-06'
import ERL01 from './views/erl-01'
import Activity06 from './views/activity-06'
import MyTrip01 from './views/my-trip-01'
import FlightStatus03 from './views/flight-status-03'
import DutyFree07 from './views/duty-free-07'
import MeetGreet04 from './views/meet-greet-04'
import Bus04 from './views/bus-04'
import Bus13 from './views/bus-13'
import Activity03 from './views/activity-03'
import MeetGreet05 from './views/meet-greet-05'
import Activity01 from './views/activity-01'
import Staycation04 from './views/staycation-04'
import ERL05 from './views/erl-05'
import Bus03 from './views/bus-03'
import Bus01 from './views/bus-01'
import DutyFree09 from './views/duty-free-09'
import Ehailing03 from './views/ehailing-03'
import HotelBook from './views/hotel-book'
import MeetGreet03 from './views/meet-greet-03'
import Bus14Edit from './views/bus-14-edit'
import HotelSelect from './views/hotel-select'
import DutyFree06 from './views/duty-free-06'
import AirportTransfer01 from './views/airport-transfer01'
import AirportTransfer08 from './views/airport-transfer08'
import AirportTransfer07 from './views/airport-transfer07'
import AirportTransfer04 from './views/airport-transfer04'
import Staycation03 from './views/staycation-03'
import MeetGreet06 from './views/meet-greet-06'
import DutyFree05 from './views/duty-free-05'
import DutyFree04 from './views/duty-free-04'
import Staycation02 from './views/staycation-02'
import MyTrip04 from './views/my-trip-04'
import Bus06 from './views/bus-06'
import OnlineShopping from './views/online-shopping'
import DutyFree08 from './views/duty-free-08'
import Bus05 from './views/bus-05'
import MeetGreet08 from './views/meet-greet-08'
import HotelPayment1 from './views/hotel-payment1'
import AirportTransfer03 from './views/airport-transfer03'
import Bus07 from './views/bus-07'
import HotelReceipt from './views/hotel-receipt'
import ERL07 from './views/erl-07'
import FlightStatus04 from './views/flight-status-04'
import Bus14 from './views/bus-14'
import HotelGallery from './views/hotel-gallery'
import HotelProduct from './views/hotel-product'
import DirectoryMap from './views/directory-map'
import FlightStatus02 from './views/flight-status-02'
import MyTrip06 from './views/my-trip-06'
import Bus09 from './views/bus-09'
import Activity04 from './views/activity-04'
import MeetGreet09 from './views/meet-greet-09'
import MyTrip05 from './views/my-trip-05'
import Bus10 from './views/bus-10'
import ExtendExplorePage from './views/extend-explore-page'
import MyTrip02 from './views/my-trip-02'
import More from './views/more'
import Activity05 from './views/activity-05'
import Bus00HomePage from './views/bus-00-home-page'
import Ehailing01 from './views/ehailing-01'
import AirportTransfer02 from './views/airport-transfer02'
import Activity07 from './views/activity-07'
import Ehailing04 from './views/ehailing-04'
import Activity02 from './views/activity-02'
import ERL02 from './views/erl-02'
import HomePage from './views/home-page'
import HotelSearch1 from './views/hotel-search1'
import AirportTransfer06 from './views/airport-transfer06'
import VisaOnArrival from "./views/visa-on-arrival";
import VisaOnArrival02 from "./views/visa-on-arrival-02";

const App = () => {
    return (
        <Router>
            <div>
                <Route component={DutyFree02} exact path="/duty-free-02"/>
                <Route component={ERL03} exact path="/erl-03"/>
                <Route component={DutyFree03} exact path="/duty-free-03"/>
                <Route component={Staycation01} exact path="/staycation-01"/>
                <Route component={FlightStatus01} exact path="/flight-status-01"/>
                <Route component={HotelPayment} exact path="/hotel-payment"/>
                <Route component={Bus02} exact path="/bus-02"/>
                <Route component={AirportTransfer05} exact path="/airport-transfer05"/>
                <Route component={MeetGreet07} exact path="/meet-greet-07"/>
                <Route component={HotelSearch2} exact path="/hotel-search2"/>
                <Route component={MyTrip03} exact path="/my-trip-03"/>
                <Route component={MeetGreet01} exact path="/meet-greet-01"/>
                <Route component={Hotel} exact path="/hotel"/>
                <Route component={MeetGreet02} exact path="/meet-greet-02"/>
                <Route component={ERL06} exact path="/erl-06"/>
                <Route component={ERL08} exact path="/erl-08"/>
                <Route component={Ehailing02} exact path="/ehailing-02"/>
                <Route component={MeetGreet09Edit} exact path="/meet-greet-09-edit"/>
                <Route component={DutyFree021} exact path="/duty-free-021"/>
                <Route component={Bus08} exact path="/bus-08"/>
                <Route component={DutyFree01} exact path="/duty-free-01"/>
                <Route component={EHailing05} exact path="/-hailing-05"/>
                <Route component={ERL04} exact path="/erl-04"/>
                <Route component={Ehailing06} exact path="/ehailing-06"/>
                <Route component={ERL01} exact path="/erl-01"/>
                <Route component={Activity06} exact path="/activity-06"/>
                <Route component={MyTrip01} exact path="/my-trip-01"/>
                <Route component={FlightStatus03} exact path="/flight-status-03"/>
                <Route component={DutyFree07} exact path="/duty-free-07"/>
                <Route component={MeetGreet04} exact path="/meet-greet-04"/>
                <Route component={Bus04} exact path="/bus-04"/>
                <Route component={Bus13} exact path="/bus-13"/>
                <Route component={Activity03} exact path="/activity-03"/>
                <Route component={MeetGreet05} exact path="/meet-greet-05"/>
                <Route component={Activity01} exact path="/activity-01"/>
                <Route component={Staycation04} exact path="/staycation-04"/>
                <Route component={ERL05} exact path="/erl-05"/>
                <Route component={Bus03} exact path="/bus-03"/>
                <Route component={Bus01} exact path="/bus-01"/>
                <Route component={DutyFree09} exact path="/duty-free-09"/>
                <Route component={Ehailing03} exact path="/ehailing-03"/>
                <Route component={HotelBook} exact path="/hotel-book"/>
                <Route component={MeetGreet03} exact path="/meet-greet-03"/>
                <Route component={Bus14Edit} exact path="/bus-14-edit"/>
                <Route component={HotelSelect} exact path="/hotel-select"/>
                <Route component={DutyFree06} exact path="/duty-free-06"/>
                <Route component={AirportTransfer01} exact path="/airport-transfer01"/>
                <Route component={AirportTransfer08} exact path="/airport-transfer08"/>
                <Route component={AirportTransfer07} exact path="/airport-transfer07"/>
                <Route component={AirportTransfer04} exact path="/airport-transfer04"/>
                <Route component={Staycation03} exact path="/staycation-03"/>
                <Route component={MeetGreet06} exact path="/meet-greet-06"/>
                <Route component={DutyFree05} exact path="/duty-free-05"/>
                <Route component={DutyFree04} exact path="/duty-free-04"/>
                <Route component={Staycation02} exact path="/staycation-02"/>
                <Route component={MyTrip04} exact path="/my-trip-04"/>
                <Route component={Bus06} exact path="/bus-06"/>
                <Route component={OnlineShopping} exact path="/online-shopping"/>
                <Route component={DutyFree08} exact path="/duty-free-08"/>
                <Route component={Bus05} exact path="/bus-05"/>
                <Route component={MeetGreet08} exact path="/meet-greet-08"/>
                <Route component={HotelPayment1} exact path="/hotel-payment1"/>
                <Route component={AirportTransfer03} exact path="/airport-transfer03"/>
                <Route component={Bus07} exact path="/bus-07"/>
                <Route component={HotelReceipt} exact path="/hotel-receipt"/>
                <Route component={ERL07} exact path="/erl-07"/>
                <Route component={FlightStatus04} exact path="/flight-status-04"/>
                <Route component={Bus14} exact path="/bus-14"/>
                <Route component={HotelGallery} exact path="/hotel-gallery"/>
                <Route component={HotelProduct} exact path="/hotel-product"/>
                <Route component={DirectoryMap} exact path="/directory-map"/>
                <Route component={FlightStatus02} exact path="/flight-status-02"/>
                <Route component={MyTrip06} exact path="/my-trip-06"/>
                <Route component={Bus09} exact path="/bus-09"/>
                <Route component={Activity04} exact path="/activity-04"/>
                <Route component={MeetGreet09} exact path="/meet-greet-09"/>
                <Route component={MyTrip05} exact path="/my-trip-05"/>
                <Route component={Bus10} exact path="/bus-10"/>
                <Route
                    component={ExtendExplorePage}
                    exact
                    path="/extend-explore-page"
                />
                <Route component={MyTrip02} exact path="/my-trip-02"/>
                <Route component={More} exact path="/more"/>
                <Route component={Activity05} exact path="/activity-05"/>
                <Route component={Bus00HomePage} exact path="/bus-00-home-page"/>
                <Route component={Ehailing01} exact path="/ehailing-01"/>
                <Route component={AirportTransfer02} exact path="/airport-transfer02"/>
                <Route component={Activity07} exact path="/activity-07"/>
                <Route component={Ehailing04} exact path="/ehailing-04"/>
                <Route component={Activity02} exact path="/activity-02"/>
                <Route component={ERL02} exact path="/erl-02"/>
                <Route component={HomePage} exact path="/"/>
                <Route component={HotelSearch1} exact path="/hotel-search1"/>
                <Route component={AirportTransfer06} exact path="/airport-transfer06"/>
                <Route component={VisaOnArrival02} exact path="/visa-on-arrival-02"/>
                <Route component={VisaOnArrival} exact path="/visa-on-arrival"/>
            </div>
        </Router>
    )
}

ReactDOM.render(<App/>, document.getElementById('app'))
