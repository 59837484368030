import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './ehailing-04.css'

const Ehailing04 = (props) => {
	return (
		<div className="ehailing04-container">
			<Helmet>
				<title>Ehailing-04 - exported project</title>
				<meta property="og:title" content="Ehailing-04 - exported project"/>
			</Helmet>
			<div className="ehailing04-container1">
				<img
					alt="image"
					src="/activity/E-hailing select location (11).svg"
					className="ehailing04-image"
				/>
				<Link to="/-hailing-05" className="ehailing04-navlink">

				</Link>
				<Link to="/ehailing-03" className="ehailing04-navlink1">
					Text
				</Link>
			</div>
		</div>
	)
}

export default Ehailing04
