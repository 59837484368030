import 'react'
import 'react-dom'
import './visa-on-arrival-02.css'
import {Link} from "react-router-dom";

export default function VisaOnArrival02() {
	return (
		<div className="visa-on-arrival02-container">
			<div className="visa-on-arrival02-container01">
				<div className="visa-on-arrival02-container02">
					<img
						alt="image"
						src="https://mycompasscommunication.blob.core.windows.net/temp/completed-success.png"
						className="visa-on-arrival02-image"
					/>
					<span className="visa-on-arrival02-text">
						感谢您的预订
					</span>
					<span className="visa-on-arrival02-text01">
						<span>我们已通过电子邮件向您发送确认订单至</span>
						<span className="visa-on-arrival02-text03">demo@gmail.com</span>
						<span>
							请按照指示领取门票
						</span>
					</span>
				</div>
				<div className="visa-on-arrival02-container03">
					<div className="visa-on-arrival02-container04">
						<div className="visa-on-arrival02-container05">
							<span className="visa-on-arrival02-text05">预订ID</span>
						</div>
						<div className="visa-on-arrival02-container06">
							<span className="visa-on-arrival02-text06">39213232546</span>
						</div>
					</div>
					<div className="visa-on-arrival02-container07">
						<div className="visa-on-arrival02-container08">
							<span className="visa-on-arrival02-text07">付款方式</span>
						</div>
						<div className="visa-on-arrival02-container09">
							<span className="visa-on-arrival02-text08">Visa* 123</span>
						</div>
					</div>
					<div className="visa-on-arrival02-container10">
						<div className="visa-on-arrival02-container11">
							<span className="visa-on-arrival02-text09">付款总额</span>
						</div>
						<div className="visa-on-arrival02-container12">
							<span className="visa-on-arrival02-text10">¥ 100.00</span>
						</div>
					</div>
				</div>
				<div className="visa-on-arrival02-container13">
					<div className="visa-on-arrival02-container14">
						<Link
							to="/activity-01"
							className="visa-on-arrival02-navlink button"
						>
							查看或管理订单
						</Link>
					</div>
					<div className="visa-on-arrival02-container15">
						<Link to="/" className="visa-on-arrival02-navlink1 button">
							返回首页
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}