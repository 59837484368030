import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './staycation-01.css'

const Staycation01 = (props) => {
  return (
    <div className="staycation01-container">
      <Helmet>
        <title>度假村</title>
        <meta property="og:title" content="Staycation-01 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/images/Staycations (1).svg"
        className="staycation01-image"
      />
      <Link to="/extend-explore-page" className="staycation01-navlink">
      文本
      </Link>
      <Link to="/staycation-02" className="staycation01-navlink1">
      文本
      </Link>
    </div>
  )
}

export default Staycation01
