import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-08.css'

const Bus08 = (props) => {
  return (
    <div className="bus08-container">
      <Helmet>
        <title>公交</title>
        <meta property="og:title" content="Bus-08 - exported project" />
      </Helmet>
      <div className="bus08-container1">
        <img
          alt="image"
          src="/images/Buses - completed.svg"
          className="bus08-image"
        />
      </div>
      <Link to="/" className="bus08-navlink">
        文本
      </Link>
    </div>
  )
}

export default Bus08
