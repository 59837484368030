import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-08.css'

const DutyFree08 = (props) => {
  return (
    <div className="duty-free08-container">
      <Helmet>
        <title>DutyFree-08 - exported project</title>
        <meta property="og:title" content="DutyFree-08 - exported project" />
      </Helmet>
      <div className="duty-free08-container1">
        <img
          alt="image"
          src="/activity/Duty free review - cont.svg"
          className="duty-free08-image"
        />
        <Link to="/duty-free-03" className="duty-free08-navlink">
           
        </Link>
        <Link to="/duty-free-09" className="duty-free08-navlink1">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree08
