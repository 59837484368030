import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hotel-payment.css'

const HotelPayment = (props) => {
  return (
    <div className="hotel-payment-container">
      <Helmet>
        <title>酒店付款</title>
        <meta property="og:title" content="HotelPayment - exported project" />
      </Helmet>
      <div className="hotel-payment-container1">
        <img
          alt="image"
          src="/images/hotel-payment.svg"
          className="hotel-payment-image"
        />
        <Link to="/hotel-book" className="hotel-payment-navlink">
                
        </Link>
        <Link to="/hotel-payment1" className="hotel-payment-navlink1">
                
        </Link>
      </div>
    </div>
  )
}

export default HotelPayment
