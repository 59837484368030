import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ehailing-03.css'

const Ehailing03 = (props) => {
  return (
    <div className="ehailing03-container">
      <Helmet>
        <title>电召车</title>
        <meta property="og:title" content="Ehailing-03 - exported project" />
      </Helmet>
      <div className="ehailing03-container1">
        <img
          alt="image"
          src="/images/E-hailing select location (10).svg"
          className="ehailing03-image"
        />
        <Link to="/ehailing-04" className="ehailing03-navlink">
           
        </Link>
        <Link to="/ehailing-02" className="ehailing03-navlink1">
          链接
        </Link>
      </div>
    </div>
  )
}

export default Ehailing03
