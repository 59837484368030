import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './erl-07.css'

const ERL07 = (props) => {
	return (
		<div className="erl07-container">
			<Helmet>
				<title>ERL-07 - exported project</title>
				<meta property="og:title" content="ERL-07 - exported project"/>
			</Helmet>
			<div className="erl07-container1">
				<img
					alt="image"
					src="/activity/ERL07-express-checkout1.svg"
					loading="eager"
					className="erl07-image"
				/>

				<Link to="/erl-08" className="erl07-navlink">
					<img
						alt="image"
						src="/activity/ERL07-express-checkout2.svg"
						loading="eager"
						className="erl07-image1"
					/>
				</Link>
				<Link to="/erl-06" className="erl07-navlink1"></Link>
			</div>
		</div>
	)
}

export default ERL07
