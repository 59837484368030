import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-04.css'

const DutyFree04 = (props) => {
  return (
    <div className="duty-free04-container">
      <Helmet>
        <title>DutyFree-04 - exported project</title>
        <meta property="og:title" content="DutyFree-04 - exported project" />
      </Helmet>
      <div className="duty-free04-container1">
        <img
          alt="image"
          src="/activity/Duty free product.svg"
          className="duty-free04-image"
        />
        <Link to="/duty-free-03" className="duty-free04-navlink">
           
        </Link>
        <Link to="/duty-free-05" className="duty-free04-navlink1">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree04
