import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './my-trip-02.css'

const MyTrip02 = (props) => {
	return (
		<div className="my-trip02-container">
			<Helmet>
				<title>MyTrip-02 - exported project</title>
				<meta property="og:title" content="MyTrip-02 - exported project"/>
			</Helmet>
			<h1 className="my-trip02-text">我的旅行</h1>
			<Link to="/my-trip-01" className="my-trip02-navlink">
				<img
					alt="image"
					src="/Homepage/black-square-for-overlay.svg"
					className="my-trip02-image"
				/>
			</Link>
			<img
				alt="image"
				src="/mytrip_assets/mytrip1.svg"
				className="my-trip02-image1"
			/>
			<h3 className="my-trip02-text01">计划和组织您的旅行</h3>
			<span className="my-trip02-text02">
				你的旅行计划有通知并自动导入智能系统行程。
			</span>
			<button type="button" className="my-trip02-button button">
				Add now →
			</button>
			<div className="my-trip02-container1">
				<div className="my-trip02-container2"></div>
				<h4 className="my-trip02-text03">添加计划</h4>
				<div className="my-trip02-container3">
					<div className="my-trip02-container4">
						<Link to="/my-trip-03" className="my-trip02-navlink1">
							<img
								alt="image"
								src="/mytrip_assets/mytrip2-3.svg"
								className="my-trip02-image2"
							/>
						</Link>
						<span className="my-trip02-hotels">酒店</span>
					</div>
					<div className="my-trip02-container5">
						<img
							alt="image"
							src="/mytrip_assets/mytrip2-1.svg"
							className="my-trip02-image3"
						/>
						<span className="my-trip02-hotels1">
							<span>航班</span>
							<br></br>
						</span>
					</div>
					<div className="my-trip02-container6">
						<img
							alt="image"
							src="/mytrip_assets/mytrip2-2.svg"
							className="my-trip02-image4"
						/>
						<span className="my-trip02-hotels2">
							<span>运输</span>
							<br></br>
						</span>
					</div>
					<div className="my-trip02-container7">
						<img
							alt="image"
							src="/mytrip_assets/mytrip2-4.svg"
							className="my-trip02-image5"
						/>
						<span className="my-trip02-hotels3">
							<span>清单</span>
							<br></br>
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MyTrip02
