import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-01.css'

const ERL01 = (props) => {
  return (
    <div className="erl01-container">
      <Helmet>
        <title>快铁</title>
        <meta property="og:title" content="ERL-01 - exported project" />
      </Helmet>
      <Link to="/erl-02" className="erl01-navlink"></Link>
      <Link to="/erl-04" className="erl01-navlink1"></Link>
      <img
        alt="image"
        src="/images/ERL011.svg"
        loading="eager"
        className="erl01-image"
      />
      <div className="erl01-container1"></div>
      <Link to="/" className="erl01-navlink2"></Link>
    </div>
  )
}

export default ERL01
