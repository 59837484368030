import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './bus-06.css'

const Bus06 = (props) => {
	return (
		<div className="bus06-container">
			<Helmet>
				<title>Bus-06 - exported project</title>
				<meta property="og:title" content="Bus-06 - exported project"/>
			</Helmet>
			<div className="bus06-container1">
				<img
					alt="image"
					src="/activity/Buses - checkout3.svg"
					loading="eager"
					className="bus06-image"
				/>
				<Link to="/bus-13" className="bus06-navlink">
					Text
				</Link>
				<Link to="/bus-07" className="bus06-navlink1">
					Text
				</Link>
				<Link to="/bus-08" className="bus06-navlink2">
					Text
				</Link>
			</div>
			<Link to="/bus-05" className="bus06-navlink3">
				Text
			</Link>
		</div>
	)
}

export default Bus06
