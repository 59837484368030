import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-13.css'

const Bus13 = (props) => {
  return (
    <div className="bus13-container">
      <Helmet>
        <title>公交</title>
        <meta property="og:title" content="Bus-13 - exported project" />
      </Helmet>
      <div className="bus13-container1">
        <Link to="/bus-06" className="bus13-navlink">
          <svg viewBox="0 0 1024 1024" className="bus13-icon">
            <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
          </svg>
        </Link>
        <span className="bus13-text">
          <span>支付方式</span>
          <br></br>
        </span>
      </div>
      <div className="bus13-container2">
        <Link to="/bus-14" className="bus13-navlink1">
          <img
            alt="image"
            src="/images/addNewCard.svg"
            className="bus13-image"
          />
        </Link>
      </div>
      <div className="bus13-container3">
        <span className="bus13-text3">借记卡/信用卡</span>
        <Link to="/bus-14-edit" className="bus13-navlink2">
          编辑
        </Link>
      </div>
      <div className="bus13-container4">
        <div className="bus13-container5">
          <svg viewBox="0 0 1316.5714285714284 1024" className="bus13-icon2">
            <path d="M1128.571 565.714h-78.857c0 0 8-21.143 37.714-102.286-0.571 0.571 8-21.143 12.571-34.857l6.857 31.429c17.714 87.429 21.714 105.714 21.714 105.714zM303.429 528.571l-33.143-168.571c-4.571-23.429-22.286-30.857-42.857-30.857h-153.143l-1.143 7.429c105.143 26.857 191.429 84 230.286 192zM405.714 329.143l-92.571 250.286-9.714-50.857c-20-53.143-68.571-102.286-123.429-124.571l77.143 291.429h100l149.143-366.286h-100.571zM485.143 696h94.857l59.429-366.857h-94.857zM924 338.286c-18.857-7.429-48.571-15.429-85.143-15.429-93.714 0-159.429 49.714-160 121.143-0.571 52.571 46.857 81.714 82.857 99.429 37.143 17.714 49.143 29.714 49.143 45.714 0 24.571-29.714 35.429-56.571 35.429-38.286 0-58.857-4.571-89.143-18.857l-12.571-6.286-13.143 82.286c22.286 10.286 62.857 19.429 105.714 19.429 99.429 0.571 164-49.143 165.143-125.143 0-41.714-25.714-73.143-80-99.429-33.143-16.571-53.143-28.571-53.143-45.714 0-15.429 17.143-31.429 54.286-31.429 30.857-0.571 53.714 5.714 70.857 13.714l8.571 4.571zM1166.857 329.143h-73.143c-22.857 0-40 6.857-49.714 30.857l-140.571 336h99.429c16-45.143 20-54.857 20-54.857h121.143c0 0 2.857 12.571 11.429 54.857h88zM1316.571 146.286v731.429c0 40-33.143 73.143-73.143 73.143h-1170.286c-40 0-73.143-33.143-73.143-73.143v-731.429c0-40 33.143-73.143 73.143-73.143h1170.286c40 0 73.143 33.143 73.143 73.143z"></path>
          </svg>
          <span className="bus13-text4">****2910</span>
        </div>
        <input type="radio" name="radio" className="bus13-radiobutton" />
      </div>
      <div className="bus13-container6">
        <Link to="/bus-06" className="bus13-navlink3">
          <img
            alt="image"
            src="/images/Confirm.svg"
            className="bus13-image1"
          />
        </Link>
      </div>
    </div>
  )
}

export default Bus13
