import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './airport-transfer07.css'

const AirportTransfer07 = (props) => {
	return (
		<div className="airport-transfer07-container">
			<Helmet>
				<title>机场转移07 -出口项目</title>
				<meta
					property="og:title"
					content="AirportTransfer07 - exported project"
				/>
			</Helmet>
			<img
				alt="image"
				src="/activity/airport-transfer-07.svg"
				className="airport-transfer07-image"
			/>
			<Link to="/airport-transfer06" className="airport-transfer07-navlink">
				Text
			</Link>
			<Link to="/airport-transfer08" className="airport-transfer07-navlink1">
				Text
			</Link>
		</div>
	)
}

export default AirportTransfer07
