import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-08.css'

const ERL08 = (props) => {
  return (
    <div className="erl08-container">
      <Helmet>
        <title>快铁</title>
        <meta property="og:title" content="ERL-08 - exported project" />
      </Helmet>
      <div className="erl08-container1">
        <img
          alt="image"
          src="/images/ERL08-express-completed.svg"
          loading="eager"
          className="erl08-image"
        />
        <Link to="/" className="erl08-navlink"></Link>
      </div>
    </div>
  )
}

export default ERL08
