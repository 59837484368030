import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './activity-07.css'

const Activity07 = (props) => {
  return (
    <div className="activity07-container">
      <Helmet>
        <title>Activity-07 - exported project</title>
        <meta property="og:title" content="Activity-07 - exported project" />
      </Helmet>
      <img
        alt="pastedImage"
        src="/activity/activity07.svg"
        loading="eager"
        className="activity07-pasted-image"
      />
      <Link to="/activity-06" className="activity07-navlink">
        Link
      </Link>
    </div>
  )
}

export default Activity07
