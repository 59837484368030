import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import BottomNavBar from '../components/bottom-nav-bar'
import './activity-01.css'

const Activity01 = (props) => {
  return (
    <div className="activity01-container">
      <Helmet>
        <title>活动</title>
        <meta property="og:title" content="Activity-01 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/images/activity01_04.jpg"
        className="activity01-image"
      />
      <Link to="/activity-02" className="activity01-bus-activity-text">
        <br></br>
        <br></br>
      </Link>
      <Link to="/activity-04" className="activity01-erl-activity-text">
        <br></br>
        <br></br>
      </Link>
      <Link to="/activity-06" className="activity01-navlink">
        链接
      </Link>
      <div className="activity01-container1">
        <BottomNavBar rootClassName="bottom-nav-bar-root-class-name6"></BottomNavBar>
      </div>
    </div>
  )
}

export default Activity01
