import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './airport-transfer02.css'

const AirportTransfer02 = (props) => {
	return (
		<div className="airport-transfer02-container">
			<Helmet>
				<title>AirportTransfer02 - exported project</title>
				<meta
					property="og:title"
					content="AirportTransfer02 - exported project"
				/>
			</Helmet>
			<img
				alt="image"
				src="/activity/airport-transfer-02.svg"
				className="airport-transfer02-image"
			/>
			<Link to="/airport-transfer03" className="airport-transfer02-navlink">
				Te
			</Link>
			<Link to="/airport-transfer01" className="airport-transfer02-navlink1">
				Text
			</Link>
		</div>
	)
}

export default AirportTransfer02
