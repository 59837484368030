import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './meet-greet-09.css'

const MeetGreet09 = (props) => {
	return (
		<div className="meet-greet09-container">
			<Helmet>
				<title>Meet-Greet-09 - exported project</title>
				<meta property="og:title" content="Meet-Greet-09 - exported project"/>
			</Helmet>
			<div className="meet-greet09-container1">
				<Link to="/meet-greet-08" className="meet-greet09-navlink">
					<svg viewBox="0 0 1024 1024" className="meet-greet09-icon">
						<path
							d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
					</svg>
				</Link>
				<span className="meet-greet09-text">
					<span>付款方式</span>
					<br></br>
				</span>
			</div>
			<div className="meet-greet09-container2"></div>
			<span className="meet-greet09-text3">
				<span>请填写您的卡详细信息</span>
				<br></br>
			</span>
			<div className="meet-greet09-container3">
				<input
					type="text"
					placeholder="卡号"
					className="meet-greet09-textinput input"
				/>
				<input
					type="text"
					placeholder="有效期至 (DD/MM/YY)"
					className="meet-greet09-textinput1 input"
				/>
				<input
					type="text"
					placeholder="CCV"
					className="meet-greet09-textinput2 input"
				/>
				<input
					type="text"
					placeholder="持卡人姓名"
					className="meet-greet09-textinput3 input"
				/>
			</div>
			<div className="meet-greet09-container4">
				<span className="meet-greet09-text6">设置为主卡</span>
				<input
					type="checkbox"
					checked="true"
					className="meet-greet09-checkbox"
				/>
			</div>
			<div className="meet-greet09-container5">
				<Link to="/meet-greet-08" className="meet-greet09-navlink1">
					<img
						alt="image"
						src="/activity/Confirm.svg"
						className="meet-greet09-image"
					/>
				</Link>
			</div>
		</div>
	)
}

export default MeetGreet09
