import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-02.css'

const ERL02 = (props) => {
  return (
    <div className="erl02-container">
      <Helmet>
        <title>ERL-02 - exported project</title>
        <meta property="og:title" content="ERL-02 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/activity/ERL022.svg"
        loading="eager"
        className="erl02-image"
      />
      <div className="erl02-container1"></div>
      <Link to="/erl-03" className="erl02-navlink"></Link>
      <Link to="/erl-01" className="erl02-navlink1"></Link>
      <Link to="/extend-explore-page" className="erl02-navlink2"></Link>
    </div>
  )
}

export default ERL02
