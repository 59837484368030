import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-07.css'

const DutyFree07 = (props) => {
  return (
    <div className="duty-free07-container">
      <Helmet>
        <title>免税</title>
        <meta property="og:title" content="DutyFree-07 - exported project" />
      </Helmet>
      <div className="duty-free07-container1">
        <img
          alt="image"
          src="/images/Duty free - completed.svg"
          className="duty-free07-image"
        />
        <Link to="/duty-free-02" className="duty-free07-navlink">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree07
