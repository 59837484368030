import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hotel-select.css'

const HotelSelect = (props) => {
  return (
    <div className="hotel-select-container">
      <Helmet>
        <title>选择酒店</title>
        <meta property="og:title" content="HotelSelect - exported project" />
      </Helmet>
      <div className="hotel-select-container1">
        <img
          alt="image"
          src="/images/Hotels -  select.svg"
          className="hotel-select-image"
        />
        <Link to="/hotel-product" className="hotel-select-navlink">
                
        </Link>
        <Link to="/hotel-book" className="hotel-select-navlink1">
                
        </Link>
      </div>
    </div>
  )
}

export default HotelSelect
