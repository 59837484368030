import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './airport-transfer04.css'

const AirportTransfer04 = (props) => {
	return (
		<div className="airport-transfer04-container">
			<Helmet>
				<title>AirportTransfer04 - exported project</title>
				<meta
					property="og:title"
					content="AirportTransfer04 - exported project"
				/>
			</Helmet>
			<div className="airport-transfer04-container1">
				<img
					alt="image"
					src="/activity/airport-transfer-04.svg"
					className="airport-transfer04-image"
				/>
				<Link to="/airport-transfer03" className="airport-transfer04-navlink">
					Text
				</Link>
				<Link to="/airport-transfer05" className="airport-transfer04-navlink1">
					Text
				</Link>
			</div>
		</div>
	)
}

export default AirportTransfer04
