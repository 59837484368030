import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './bottom-nav-bar.css'

const BottomNavBar = (props) => {
  return (
    <div className={`bottom-nav-bar-container ${props.rootClassName} `}>
      <Link to="/" className="bottom-nav-bar-navlink">
        <div className="bottom-nav-bar-homepage">
          <img
            alt={props.image_alt}
            src={props.image_src}
            loading="eager"
            className="bottom-nav-bar-image"
          />
          <span className="bottom-nav-bar-text">{props.text}</span>
        </div>
      </Link>
      <Link to="/my-trip-01" className="bottom-nav-bar-navlink1">
        <div className="bottom-nav-bar-trip">
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="bottom-nav-bar-image1"
          />
          <span className="bottom-nav-bar-text1">{props.text1}</span>
        </div>
      </Link>
      <div className="bottom-nav-bar-explore">
        <div className="bottom-nav-bar-container1">
          <Link to="/extend-explore-page" className="bottom-nav-bar-navlink2">
            <div className="bottom-nav-bar-container2">
              <img
                alt={props.image_alt2}
                src={props.image_src2}
                className="bottom-nav-bar-image2"
              />
            </div>
          </Link>
        </div>
        <span className="bottom-nav-bar-text2">{props.text2}</span>
      </div>
      <Link to="/activity-01" className="bottom-nav-bar-navlink3">
        <div className="bottom-nav-bar-activity">
          <img
            alt={props.Activity_alt}
            src={props.Activity_src}
            className="bottom-nav-bar-activity1"
          />
          <span className="bottom-nav-bar-text3">{props.text3}</span>
        </div>
      </Link>
      <div className="bottom-nav-bar-more">
        <Link to="/more" className="bottom-nav-bar-navlink4">
          <img
            alt={props.Account_alt}
            src={props.Account_src}
            className="bottom-nav-bar-account"
          />
        </Link>
        <span className="bottom-nav-bar-text4">{props.text4}</span>
      </div>
    </div>
  )
}

BottomNavBar.defaultProps = {
  text1: '旅行',
  image_alt: '图片',
  Account_src: '/Homepage/element-3-200h.png',
  text4: '更多',
  link_text: 'https://example.com',
  image_src1: '/Homepage/gallery-200h.png',
  text3: '活动',
  text5: '链接',
  text: '首页',
  Activity_src: '/Homepage/receipt-minus-200h.png',
  image_alt1: '图片',
  image_src: '/Homepage/vector%20(3)-200w.png',
  text2: '探讨',
  Activity_alt: '图片',
  image_src2: '/Homepage/explore-200h.png',
  rootClassName: '',
  Account_alt: '图片',
  image_alt2: '图片',
}

BottomNavBar.propTypes = {
  text1: PropTypes.string,
  image_alt: PropTypes.string,
  Account_src: PropTypes.string,
  text4: PropTypes.string,
  link_text: PropTypes.string,
  image_src1: PropTypes.string,
  text3: PropTypes.string,
  text5: PropTypes.string,
  text: PropTypes.string,
  Activity_src: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src: PropTypes.string,
  text2: PropTypes.string,
  Activity_alt: PropTypes.string,
  image_src2: PropTypes.string,
  rootClassName: PropTypes.string,
  Account_alt: PropTypes.string,
  image_alt2: PropTypes.string,
}

export default BottomNavBar
