import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './flight-status-01.css'

const FlightStatus01 = (props) => {
  return (
    <div className="flight-status01-container">
      <Helmet>
        <title>飞行状态</title>
        <meta
          property="og:title"
          content="Flight-Status-01 - exported project"
        />
      </Helmet>
      <Link to="/" className="flight-status01-navlink">
        <svg viewBox="0 0 1024 1024" className="flight-status01-icon">
          <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
        </svg>
      </Link>
      <span className="flight-status01-text">
        <span>
        与我们一起轻松跟踪您的航班状态并接收通知关于你的航班。
        </span>
        <br className="flight-status01-text2"></br>
        <br></br>
        <br></br>
      </span>
      <span className="flight-status01-text5">飞行状态</span>
      <img
        alt="image"
        src="https://mycompasscommunication.blob.core.windows.net/temp/FlightStatus1.svg"
        className="flight-status01-image"
      />
      <div className="flight-status01-container1">
        <span className="flight-status01-text6">日期</span>
        <input
          type="text"
          placeholder="Flight Number"
          className="flight-status01-textinput input"
        />
        <input
          type="text"
          placeholder="Mon, 26 June 2023"
          className="flight-status01-textinput1 input"
        />
        <svg viewBox="0 0 1024 1024" className="flight-status01-icon2">
          <path d="M854 896v-554h-684v554h684zM854 128q34 0 59 26t25 60v682q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-682q0-34 25-60t59-26h44v-86h84v86h428v-86h84v86h44z"></path>
        </svg>
        <Link to="/flight-status-04" className="flight-status01-navlink1">
          <img
            alt="image"
            src="/images/FlightStatus5.svg"
            className="flight-status01-image1"
          />
        </Link>
      </div>
      <img
        alt="image"
        src="/activity/FlightStatus2.svg"
        className="flight-status01-image2"
      />
    </div>
  )
}

export default FlightStatus01
