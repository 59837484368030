import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './activity-02.css'

const Activity02 = (props) => {
  return (
    <div className="activity02-container">
      <Helmet>
        <title>Activity-02 - exported project</title>
        <meta property="og:title" content="Activity-02 - exported project" />
      </Helmet>
      <img
        alt="pastedImage"
        src="/activity/activity02.svg"
        loading="eager"
        className="activity02-pasted-image"
      />
      <div className="activity02-container1">
        <button type="button" className="activity02-receipt-button button">
          <Link to="/activity-03" className="activity02-navlink">
            <span>演出门票</span>
            <br></br>
          </Link>
        </button>
      </div>
      <Link to="/activity-01" className="activity02-navlink1">
        链接
      </Link>
    </div>
  )
}

export default Activity02
