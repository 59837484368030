import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './meet-greet-06.css'

const MeetGreet06 = (props) => {
	return (
		<div className="meet-greet06-container">
			<Helmet>
				<title>Meet-Greet-06 - exported project</title>
				<meta property="og:title" content="Meet-Greet-06 - exported project"/>
			</Helmet>
			<div className="meet-greet06-container1">
				<Link to="/meet-greet-05" className="meet-greet06-navlink">
					<svg viewBox="0 0 1024 1024" className="meet-greet06-icon">
						<path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
					</svg>
				</Link>
				<span className="meet-greet06-text">
					<span>Review Application</span>
					<br></br>
				</span>
				<Link to="/meet-greet-01" className="meet-greet06-navlink1">
					<svg viewBox="0 0 1024 1024" className="meet-greet06-icon2">
						<path
							d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
					</svg>
				</Link>
			</div>
			<img
				alt="image"
				src="/activity/Meet&Greet1.svg?"
				className="meet-greet06-image"
			/>
			<div className="meet-greet06-container2">
				<input
					type="text"
					placeholder="Voucher Code"
					className="meet-greet06-textinput input"
				/>
				<button type="button" className="meet-greet06-button button">
					Apply
				</button>
			</div>
			<img
				alt="image"
				src="/activity/Payment%20Summary.svg"
				className="meet-greet06-image1"
			/>
			<Link to="/meet-greet-08" className="meet-greet06-navlink2">
				<img
					alt="image"
					src="/activity/Payment%20Summary1.svg"
					className="meet-greet06-image2"
				/>
			</Link>
			<img
				alt="image"
				src="/activity/Meet&Greet16.svg"
				className="meet-greet06-image3"
			/>
			<div className="meet-greet06-container3">
				<div className="meet-greet06-container4">
					<span className="meet-greet06-text03">
						<span> ￥ 100.00</span>
						<br></br>
					</span>
					<span className="meet-greet06-text06">
						<span>付款总额</span>
						<br></br>
						<br></br>
						<br></br>
					</span>
				</div>
				<Link to="/meet-greet-07" className="meet-greet06-navlink3">
					<img
						alt="image"
						src="/activity/Meet&Greet11(1).svg"
						className="meet-greet06-image4"
					/>
				</Link>
			</div>
		</div>
	)
}

export default MeetGreet06
