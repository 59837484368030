import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './hotel-product.css'

const HotelProduct = (props) => {
	return (
		<div className="hotel-product-container">
			<Helmet>
				<title>HotelProduct - exported project</title>
				<meta property="og:title" content="HotelProduct - exported project"/>
			</Helmet>
			<div className="hotel-product-container1">
				 <img
          alt="image"
          src="/activity/hotel_product.svg"
          className="hotel-product-image"
        />
				<Link to="/hotel-search2" className="hotel-product-navlink">

				</Link>
				<Link to="/hotel-gallery" className="hotel-product-navlink1">

				</Link>
				<Link to="/hotel-gallery" className="hotel-product-navlink2">

				</Link>
			</div>
			<div className="hotel-product-container2">
				<Link to="/hotel-select" className="hotel-product-navlink3">
				</Link>
			</div>
		</div>
	)
}

export default HotelProduct
