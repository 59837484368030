import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-05.css'

const DutyFree05 = (props) => {
  return (
    <div className="duty-free05-container">
      <Helmet>
        <title>DutyFree-05 - exported project</title>
        <meta property="og:title" content="DutyFree-05 - exported project" />
      </Helmet>
      <div className="duty-free05-container1">
        <img
          alt="image"
          src="/activity/Duty free fill in.svg"
          className="duty-free05-image"
        />
        <Link to="/duty-free-04" className="duty-free05-navlink">
           
        </Link>
        <Link to="/duty-free-06" className="duty-free05-navlink1">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree05
