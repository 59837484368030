import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-01.css'

const MeetGreet01 = (props) => {
  return (
    <div className="meet-greet01-container">
      <Helmet>
        <title>见面会</title>
        <meta property="og:title" content="Meet-Greet-01 - exported project" />
      </Helmet>
      <Link to="/" className="meet-greet01-navlink">
        <svg viewBox="0 0 1024 1024" className="meet-greet01-icon">
          <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
        </svg>
      </Link>
      <span className="meet-greet01-text">
        <span>
        我们将帮助您绕过长队，让您轻松通过机场。
        </span>
        <br className="meet-greet01-text02"></br>
        <br></br>
      </span>
      <span className="meet-greet01-text04">见面 &amp; 问候</span>
      <img
        alt="image"
        src="https://mycompasscommunication.blob.core.windows.net/temp/Meet&Greet4.svg"
        className="meet-greet01-image"
      />
      <div className="meet-greet01-container01">
        <img
          alt="image"
          src="/images/Meet&Greet5.svg"
          className="meet-greet01-image1"
        />
        <img
          alt="image"
          src="/images/Meet&Greet6.svg"
          className="meet-greet01-image2"
        />
        <div className="meet-greet01-container02"></div>
        <span className="meet-greet01-text05">日期</span>
        <input
          type="text"
          placeholder="Mon, 26 June 2023"
          className="meet-greet01-textinput input"
        />
        <input
          type="text"
          placeholder="Number of Travellers"
          className="meet-greet01-textinput1 input"
        />
        <svg viewBox="0 0 1024 1024" className="meet-greet01-icon02">
          <path d="M854 896v-554h-684v554h684zM854 128q34 0 59 26t25 60v682q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-682q0-34 25-60t59-26h44v-86h84v86h428v-86h84v86h44z"></path>
        </svg>
        <Link to="/meet-greet-02" className="meet-greet01-navlink1">
          <img
            alt="image"
            src="/images/mytrip-next-btn.svg"
            className="meet-greet01-image3"
          />
        </Link>
      </div>
      <div className="meet-greet01-container03">
        <div className="meet-greet01-container04">
          <svg
            viewBox="0 0 1316.5714285714284 1024"
            className="meet-greet01-icon04"
          >
            <path d="M109.714 658.286c48 0 48-73.143 0-73.143s-48 73.143 0 73.143zM951.429 625.143c-45.143-58.857-89.143-118.857-140.571-172.571l-71.429 80c-62.857 71.429-175.429 69.714-236.571-3.429-43.429-52.571-43.429-128 1.143-180l101.143-117.714c-35.429-18.286-78.286-12-116.571-12-33.714 0-66.286 13.714-90.286 37.714l-90.286 90.286h-88.571v310.857c25.143 0 48-3.429 68 16l169.714 166.857c34.857 33.714 80 63.429 129.714 63.429 25.714 0 53.143-8.571 71.429-26.857 42.857 14.857 92.571-9.143 105.714-53.143 27.429 2.286 52.571-6.286 72.571-25.143 13.143-12 30.286-36 28.571-54.857 5.143 5.143 17.714 5.714 24.571 5.714 68 0 103.429-71.429 61.714-125.143zM1042.286 658.286h54.857v-292.571h-53.143l-89.714-102.857c-24-27.429-60-43.429-96.571-43.429h-95.429c-32 0-62.857 14.286-83.429 38.286l-119.429 138.857c-21.143 25.143-21.143 60.571-0.571 85.714 32.571 38.857 92.571 39.429 126.286 1.714l110.286-124.571c26.286-29.143 74.286-1.714 62.286 35.429 21.714 25.143 45.143 49.714 66.286 74.857 28.571 35.429 56 72.571 84 108.571 17.714 22.857 30.857 50.286 34.286 80zM1206.857 658.286c48 0 48-73.143 0-73.143s-48 73.143 0 73.143zM1316.571 329.143v365.714c0 20-16.571 36.571-36.571 36.571h-248c-20.571 49.714-65.714 82.857-118.286 90.286-24.571 36-62.286 63.429-104.571 72.571-31.429 40-82.286 64-133.143 60.571-94.286 53.143-200.571 6.857-270.857-62.286l-164-161.143h-204.571c-20 0-36.571-16.571-36.571-36.571v-384c0-20 16.571-36.571 36.571-36.571h240.571c66.286-66.286 112-128 211.429-128h66.857c37.143 0 73.143 11.429 103.429 32 30.286-20.571 66.286-32 103.429-32h95.429c108.571 0 153.714 70.857 219.429 146.286h202.857c20 0 36.571 16.571 36.571 36.571z"></path>
          </svg>
          <div className="meet-greet01-container05">
            <span className="meet-greet01-text06">
              <span>欢迎您</span>
              <br></br>
            </span>
            <span className="meet-greet01-text09">
              <span>我们的专职搬运工将欢迎您 </span>
              <span>你在机场</span>
              <br className="meet-greet01-text12"></br>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
        <div className="meet-greet01-container06">
          <svg
            viewBox="0 0 805.1565714285713 1024"
            className="meet-greet01-icon06"
          >
            <path d="M800.571 199.429l-365.714 731.429c-6.286 12.571-18.857 20-32.571 20-2.857 0-5.714-0.571-8.571-1.143-16.571-4-28-18.286-28-35.429v-329.143h-329.143c-17.143 0-31.429-11.429-35.429-28s4-33.714 18.857-41.143l731.429-365.714c5.143-2.857 10.857-4 16.571-4 9.714 0 18.857 3.429 25.714 10.857 11.429 10.857 14.286 28 6.857 42.286z"></path>
          </svg>
          <div className="meet-greet01-container07">
            <span className="meet-greet01-text15">
              <span>快速通道援助</span>
              <br className="meet-greet01-text17"></br>
              <br></br>
              <br></br>
            </span>
            <span className="meet-greet01-text20">
              <span>
              各机场提供协助并加快速度
              手续包括移民、安全和海关。
              </span>
              <br className="meet-greet01-text22"></br>
              <br></br>
            </span>
          </div>
        </div>
        <div className="meet-greet01-container08">
          <svg viewBox="0 0 1024 1024" className="meet-greet01-icon08">
            <path d="M960 608l-288 288-96-96-64 64 160 160 352-352z"></path>
            <path d="M448 768h320v-115.128c-67.22-39.2-156.308-66.11-256-74.26v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h448v-64z"></path>
          </svg>
          <div className="meet-greet01-container09">
            <span className="meet-greet01-text24">
              <span>护送</span>
              <br></br>
              <br></br>
              <br></br>
            </span>
            <span className="meet-greet01-text29">
              <span>
              然后，您将在登机前被护送至登机口登机
              </span>
              <br className="meet-greet01-text31"></br>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
        <div className="meet-greet01-container10">
          <svg viewBox="0 0 1024 1024" className="meet-greet01-icon11">
            <path d="M1004 804.571l20 178.857c1.143 10.286-2.286 20.571-9.143 28.571-6.857 7.429-17.143 12-27.429 12h-950.857c-10.286 0-20.571-4.571-27.429-12-6.857-8-10.286-18.286-9.143-28.571l20-178.857h984zM950.857 325.143l49.143 442.857h-976l49.143-442.857c2.286-18.286 17.714-32.571 36.571-32.571h146.286v73.143c0 40.571 32.571 73.143 73.143 73.143s73.143-32.571 73.143-73.143v-73.143h219.429v73.143c0 40.571 32.571 73.143 73.143 73.143s73.143-32.571 73.143-73.143v-73.143h146.286c18.857 0 34.286 14.286 36.571 32.571zM731.429 219.429v146.286c0 20-16.571 36.571-36.571 36.571s-36.571-16.571-36.571-36.571v-146.286c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286v146.286c0 20-16.571 36.571-36.571 36.571s-36.571-16.571-36.571-36.571v-146.286c0-121.143 98.286-219.429 219.429-219.429s219.429 98.286 219.429 219.429z"></path>
          </svg>
          <div className="meet-greet01-container11">
            <span className="meet-greet01-text35">
              <span>行李服务</span>
              <br className="meet-greet01-text37"></br>
              <br></br>
              <br></br>
              <br></br>
            </span>
            <span className="meet-greet01-text41">
              <span>
              行李员会保管您的行李直至办理入住手续。
              </span>
              <br className="meet-greet01-text43"></br>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetGreet01
