import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-02.css'

const MeetGreet02 = (props) => {
  return (
    <div className="meet-greet02-container">
      <Helmet>
        <title>见面会</title>
        <meta property="og:title" content="Meet-Greet-02 - exported project" />
      </Helmet>
      <Link to="/meet-greet-01" className="meet-greet02-navlink">
        <svg viewBox="0 0 1024 1024" className="meet-greet02-icon">
          <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
        </svg>
      </Link>
      <span className="meet-greet02-text">
        <span>见面会 - 抵达服务</span>
        <br></br>
      </span>
      <span className="meet-greet02-text03">
        <span>套餐包含</span>
        <br></br>
      </span>
      <div className="meet-greet02-container1">
        <span className="meet-greet02-text06">
          <span>01</span>
          <br></br>
        </span>
        <span className="meet-greet02-text09">
          <span>VIP移民快速通道</span>
          <br></br>
          <br></br>
        </span>
      </div>
      <div className="meet-greet02-container2">
        <span className="meet-greet02-text13">
          <span>02</span>
          <br></br>
          <br></br>
        </span>
        <span className="meet-greet02-text17">
          见面 &amp; 当您抵达时，我们的专业工作人员将提供迎宾服务
        </span>
      </div>
      <div className="meet-greet02-container3">
        <span className="meet-greet02-text18">
          <span>03</span>
          <br></br>
          <br></br>
        </span>
        <span className="meet-greet02-text22">
        文档协助，省去麻烦
        </span>
      </div>
      <div className="meet-greet02-container4">
        <span className="meet-greet02-text23">
          <span>取消政策</span>
          <br className="meet-greet02-text25"></br>
          <br></br>
          <br></br>
          <br></br>
        </span>
        <span className="meet-greet02-text29">
          <span>
            小于48小时将获得全额退款
          </span>
          <br></br>
          <span> 活动开始前</span>
          <br className="meet-greet02-text33"></br>
          <br></br>
          <br></br>
        </span>
      </div>
      <div className="meet-greet02-container5">
        <span className="meet-greet02-text36">
          <span>见面信息</span>
          <br></br>
        </span>
        <span className="meet-greet02-text39">
          <span>
          在登机桥与我们的工作人员会面。寻找一个标志
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>和</span>
          <span>上面有你的名字</span>
          <br className="meet-greet02-text44"></br>
          <br></br>
          <br></br>
          <br></br>
        </span>
      </div>
      <div className="meet-greet02-container6">
        <Link to="/meet-greet-03" className="meet-greet02-navlink1">
          <img
            alt="image"
            src="/images/Meet&Greet7.svg"
            className="meet-greet02-image"
          />
        </Link>
      </div>
    </div>
  )
}

export default MeetGreet02
