import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hotel.css'

const Hotel = (props) => {
  return (
    <div className="hotel-container">
      <Helmet>
        <title>酒店</title>
        <meta property="og:title" content="Hotel - exported project" />
      </Helmet>
      <div className="hotel-container1">
        <img
          alt="image"
          src="/images/Hotels.svg"
          className="hotel-image"
        />
        <Link to="/" className="hotel-navlink">
                
        </Link>
        <Link to="/hotel-search1" className="hotel-navlink1">
                
        </Link>
      </div>
    </div>
  )
}

export default Hotel
