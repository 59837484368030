import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-04.css'

const MeetGreet04 = (props) => {
  return (
    <div className="meet-greet04-container">
      <Helmet>
        <title>见面会</title>
        <meta property="og:title" content="Meet-Greet-04 - exported project" />
      </Helmet>
      <div className="meet-greet04-container1">
        <Link to="/meet-greet-03" className="meet-greet04-navlink">
          <svg viewBox="0 0 1024 1024" className="meet-greet04-icon">
            <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
          </svg>
        </Link>
        <span className="meet-greet04-text">
          <span>预订抵达服务</span>
          <br className="meet-greet04-text02"></br>
          <br></br>
          <br></br>
        </span>
        <Link to="/meet-greet-01" className="meet-greet04-navlink1">
          <svg viewBox="0 0 1024 1024" className="meet-greet04-icon2">
            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
          </svg>
        </Link>
      </div>
      <div className="meet-greet04-container2"></div>
      <span className="meet-greet04-text05">
        <span>步骤 2/3</span>
        <br></br>
      </span>
      <span className="meet-greet04-text08">
        <span>请提供您抵达航班的详细信息。</span>
        <br className="meet-greet04-text10"></br>
        <br></br>
        <br></br>
        <br></br>
      </span>
      <div className="meet-greet04-container3">
        <input
          type="text"
          placeholder="姓"
          className="meet-greet04-textinput input"
        />
        <input
          type="text"
          placeholder="名"
          className="meet-greet04-textinput1 input"
        />
        <input
          type="text"
          placeholder="日期"
          className="meet-greet04-textinput2 input"
        />
        <svg viewBox="0 0 1024 1024" className="meet-greet04-icon4">
          <path d="M854 896v-554h-684v554h684zM854 128q34 0 59 26t25 60v682q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-682q0-34 25-60t59-26h44v-86h84v86h428v-86h84v86h44z"></path>
        </svg>
        <input
          type="text"
          placeholder="电子邮箱"
          className="meet-greet04-textinput3 input"
        />
        <input
          type="text"
          placeholder="手机号"
          className="meet-greet04-textinput4 input"
        />
      </div>
      <div className="meet-greet04-container4">
        <div className="meet-greet04-container5">
          <span className="meet-greet04-text14">
            <span>马币 100.00</span>
            <br></br>
          </span>
          <span className="meet-greet04-text17">
            <span>总共支付</span>
            <br></br>
            <br></br>
            <br></br>
          </span>
        </div>
        <Link to="/meet-greet-05" className="meet-greet04-navlink2">
          <img
            alt="image"
            src="/images/mytrip-next-btn.svg"
            className="meet-greet04-image"
          />
        </Link>
      </div>
    </div>
  )
}

export default MeetGreet04
