import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-14-edit.css'

const Bus14Edit = (props) => {
  return (
    <div className="bus1-edit-container">
      <Helmet>
        <title>公交</title>
        <meta property="og:title" content="Bus-14-Edit - exported project" />
      </Helmet>
      <div className="bus1-edit-container1">
        <Link to="/bus-13" className="bus1-edit-navlink">
          <svg viewBox="0 0 1024 1024" className="bus1-edit-icon">
            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
          </svg>
        </Link>
        <span className="bus1-edit-text">
          <span>支付方式d</span>
          <br></br>
        </span>
      </div>
      <div className="bus1-edit-container2"></div>
      <span className="bus1-edit-text3">
        <span>请填写您的卡详细信息</span>
        <br></br>
      </span>
      <div className="bus1-edit-container3">
        <input
          type="text"
          value="11122910"
          placeholder="卡号"
          className="bus1-edit-textinput input"
        />
        <input
          type="text"
          value="22/11/2027"
          placeholder="有效期 (DD/MM/YY)"
          className="bus1-edit-textinput1 input"
        />
        <input
          type="text"
          value="133"
          placeholder="CCV"
          className="bus1-edit-textinput2 input"
        />
        <input
          type="text"
          value=" "
          placeholder="持卡人"
          className="bus1-edit-textinput3 input"
        />
      </div>
      <div className="bus1-edit-container4">
        <span className="bus1-edit-text6">Set as primary card</span>
        <input type="checkbox" checked="true" className="bus1-edit-checkbox" />
      </div>
      <div className="bus1-edit-container5">
        <Link to="/bus-13" className="bus1-edit-navlink1">
          <img
            alt="image"
            src="/images/Confirm.svg"
            className="bus1-edit-image"
          />
        </Link>
      </div>
    </div>
  )
}

export default Bus14Edit
