import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './staycation-03.css'

const Staycation03 = (props) => {
  return (
    <div className="staycation03-container">
      <Helmet>
        <title>Staycation-03 - exported project</title>
        <meta property="og:title" content="Staycation-03 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/activity/Staycations - search state (4).svg"
        className="staycation03-image"
      />
      <Link to="/staycation-04" className="staycation03-navlink">
        Link
      </Link>
      <Link to="/staycation-02" className="staycation03-navlink1">
         
      </Link>
    </div>
  )
}

export default Staycation03
