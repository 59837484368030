import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './bus-05.css'

const Bus05 = (props) => {
	return (
		<div className="bus05-container">
			<Helmet>
				<title>Bus-05 - exported project</title>
				<meta property="og:title" content="Bus-05 - exported project"/>
			</Helmet>
			<div className="bus05-container1">
				<img
					alt="image"
					src="/activity/Buses - seats (1).svg"
					className="bus05-image"
				/>
			</div>
			<Link to="/bus-06" className="bus05-navlink">
				Text
			</Link>
			<Link to="/bus-03" className="bus05-navlink1">
				Text
			</Link>
		</div>
	)
}

export default Bus05
