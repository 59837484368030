import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './airport-transfer06.css'

const AirportTransfer06 = (props) => {
	return (
		<div className="airport-transfer06-container">
			<Helmet>
				<title>机场转移06 -出口项目</title>
				<meta
					property="og:title"
					content="AirportTransfer06 - exported project"
				/>
			</Helmet>
			<img
				alt="image"
				src="/activity/airport-transfer-06.svg"
				className="airport-transfer06-image"
			/>
			<Link to="/airport-transfer05" className="airport-transfer06-navlink">
				Text
			</Link>
			<Link to="/airport-transfer07" className="airport-transfer06-navlink1">
				Text
			</Link>
		</div>
	)
}

export default AirportTransfer06
