import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-05.css'

const MeetGreet05 = (props) => {
  return (
    <div className="meet-greet05-container">
      <Helmet>
        <title>见面会</title>
        <meta property="og:title" content="Meet-Greet-05 - exported project" />
      </Helmet>
      <div className="meet-greet05-container1">
        <Link to="/meet-greet-04" className="meet-greet05-navlink">
          <svg viewBox="0 0 1024 1024" className="meet-greet05-icon">
            <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
          </svg>
        </Link>
        <span className="meet-greet05-text">
          <span>预订抵达服务</span>
          <br className="meet-greet05-text02"></br>
          <br></br>
          <br></br>
        </span>
        <Link to="/meet-greet-01" className="meet-greet05-navlink1">
          <svg viewBox="0 0 1024 1024" className="meet-greet05-icon2">
            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
          </svg>
        </Link>
      </div>
      <div className="meet-greet05-container2"></div>
      <span className="meet-greet05-text05">
        <span>步骤 3/3</span>
        <br></br>
      </span>
      <span className="meet-greet05-text08">
        <span>请填写帐单地址</span>
        <br></br>
        <br></br>
        <br></br>
      </span>
      <div className="meet-greet05-container3">
        <input
          type="text"
          placeholder="地址 1"
          className="meet-greet05-textinput input"
        />
        <input
          type="text"
          placeholder="地址 2"
          className="meet-greet05-textinput1 input"
        />
        <input
          type="text"
          placeholder="国家"
          className="meet-greet05-textinput2 input"
        />
        <input
          type="text"
          placeholder="邮编"
          className="meet-greet05-textinput3 input"
        />
        <input
          type="text"
          placeholder="省"
          className="meet-greet05-textinput4 input"
        />
        <input
          type="text"
          placeholder="市"
          className="meet-greet05-textinput5 input"
        />
      </div>
      <div className="meet-greet05-container4">
        <div className="meet-greet05-container5">
          <span className="meet-greet05-text13">
            <span>马币 100.00</span>
            <br></br>
          </span>
          <span className="meet-greet05-text16">
            <span>总共支付</span>
            <br></br>
            <br></br>
            <br></br>
          </span>
        </div>
        <Link to="/meet-greet-06" className="meet-greet05-navlink2">
          <img
            alt="image"
            src="/images/Meet&Greet10.svg"
            className="meet-greet05-image"
          />
        </Link>
      </div>
    </div>
  )
}

export default MeetGreet05
