import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-03.css'

const ERL03 = (props) => {
  return (
    <div className="erl03-container">
      <Helmet>
        <title>快铁</title>
        <meta property="og:title" content="ERL-03 - exported project" />
      </Helmet>
      <div className="erl03-container1">
        <img
          alt="image"
          src="/images/ERL03-transit.svg"
          loading="eager"
          className="erl03-image"
        />
        <div className="erl03-container2"></div>
        <Link to="/erl-02" className="erl03-navlink"></Link>
      </div>
    </div>
  )
}

export default ERL03
