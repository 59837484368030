import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-09.css'

const Bus09 = (props) => {
  return (
    <div className="bus09-container">
      <Helmet>
        <title>Bus-09 - exported project</title>
        <meta property="og:title" content="Bus-09 - exported project" />
      </Helmet>
      <div className="bus09-container1">
        <img
          alt="image"
          src="/activity/Help centre.svg"
          className="bus09-image"
        />
      </div>
      <Link to="/bus-00-home-page" className="bus09-navlink">
        Text
      </Link>
    </div>
  )
}

export default Bus09
