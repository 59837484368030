import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-03.css'

const Bus03 = (props) => {
  return (
    <div className="bus03-container">
      <Helmet>
        <title>公交</title>
        <meta property="og:title" content="Bus-03 - exported project" />
      </Helmet>
      <div className="bus03-container1">
        <img
          alt="image"
          src="/images/Buses - add passenger.svg"
          className="bus03-image"
        />
        <Link to="/bus-05" className="bus03-navlink">
          <span>下一步</span>
          <br></br>
        </Link>
        <Link to="/bus-04" className="bus03-navlink1">
          <span>添加下一位乘客</span>
          <br></br>
        </Link>
      </div>
      <Link to="/bus-02" className="bus03-navlink2">
        文本
      </Link>
    </div>
  )
}

export default Bus03
