import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './staycation-04.css'

const Staycation04 = (props) => {
  return (
    <div className="staycation04-container">
      <Helmet>
        <title>度假村</title>
        <meta property="og:title" content="Staycation-04 - exported project" />
      </Helmet>
      <img
        alt="image"
        src="/images/Staycation - search state.svg"
        className="staycation04-image"
      />
      <Link to="/" className="staycation04-navlink">
        文本
      </Link>
    </div>
  )
}

export default Staycation04
