import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ehailing-06.css'

const Ehailing06 = (props) => {
  return (
    <div className="ehailing06-container">
      <Helmet>
        <title>电召车</title>
        <meta property="og:title" content="Ehailing-06 - exported project" />
      </Helmet>
      <div className="ehailing06-container1">
        <img
          alt="image"
          src="/images/E-hailing select location.svg"
          className="ehailing06-image"
        />
        <Link to="/" className="ehailing06-text">
           
        </Link>
      </div>
    </div>
  )
}

export default Ehailing06
