import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './ehailing-02.css'

const Ehailing02 = (props) => {
  return (
    <div className="ehailing02-container">
      <Helmet>
        <title>电召车</title>
        <meta property="og:title" content="Ehailing-02 - exported project" />
      </Helmet>
      <div className="ehailing02-container1"></div>
      <img
        alt="image"
        src="/images/E-hailing select location (9).svg"
        className="ehailing02-image"
      />
      <Link to="/ehailing-03" className="ehailing02-navlink">
        文本
      </Link>
      <Link to="/ehailing-01" className="ehailing02-navlink1">
        <span> </span>
        <br></br>
        <br></br>
      </Link>
    </div>
  )
}

export default Ehailing02
