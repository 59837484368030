import React from 'react'

import PropTypes from 'prop-types'

import './ignored-bugged-unable-to-delete.css'

const IgnoredBuggedUnableToDelete = (props) => {
  return (
    <footer
      className={`ignored-bugged-unable-to-delete-footer ${props.rootClassName} `}
    ></footer>
  )
}

IgnoredBuggedUnableToDelete.defaultProps = {
  image_alt: 'logo',
  text: '© 2021 teleportHQ, All Rights Reserved.',
  rootClassName: '',
  image_src: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
}

IgnoredBuggedUnableToDelete.propTypes = {
  image_alt: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
}

export default IgnoredBuggedUnableToDelete
