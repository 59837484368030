import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './activity-06.css'

const Activity06 = (props) => {
  return (
    <div
      img="/images/activity05_svg.svg"
      className="activity06-container"
    >
      <Helmet>
        <title>活动</title>
        <meta property="og:title" content="Activity-06 - exported project" />
      </Helmet>
      <div className="activity06-container1">
        <img
          alt="pastedImage"
          src="/images/activity06.svg"
          loading="eager"
          className="activity06-pasted-image"
        />
        <Link to="/activity-07" className="activity06-bus-activity-text">
          <br></br>
          <br></br>
        </Link>
        <Link to="/activity-01" className="activity06-navlink">
          Link
        </Link>
      </div>
    </div>
  )
}

export default Activity06
