import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './hotel-search1.css'

const HotelSearch1 = (props) => {
	return (
		<div className="hotel-search1-container">
			<Helmet>
				<title>酒店搜索1 -导出项目</title>
				<meta property="og:title" content="HotelSearch1 - exported project"/>
			</Helmet>
			<div className="hotel-search1-container1">
				<img
					alt="image"
					src="/activity/Staycations - search state (3).svg"
					className="hotel-search1-image"
				/>
				<Link to="/hotel" className="hotel-search1-navlink">

				</Link>
				<Link to="/hotel-search2" className="hotel-search1-navlink1">
				</Link>
			</div>
		</div>
	)
}

export default HotelSearch1
