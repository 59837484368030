import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './activity-05.css'

const Activity05 = (props) => {
  return (
    <div
      img="https://mycompasscommunication.blob.core.windows.net/temp/activity05_svg.svg"
      className="activity05-container"
    >
      <Helmet>
        <title>Activity-05 - exported project</title>
        <meta property="og:title" content="Activity-05 - exported project" />
      </Helmet>
      <div className="activity05-container1">
        <img
          alt="pastedImage"
          src="/activity/activity05.svg"
          loading="eager"
          className="activity05-pasted-image"
        />
        <Link to="/activity-04" className="activity05-navlink">
          Link
        </Link>
      </div>
    </div>
  )
}

export default Activity05
