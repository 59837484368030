import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './bus-14.css'

const Bus14 = (props) => {
	return (
		<div className="bus14-container">
			<Helmet>
				<title>Bus-14 - exported project</title>
				<meta property="og:title" content="Bus-14 - exported project"/>
			</Helmet>
			<div className="bus14-container1">
				<Link to="/bus-13" className="bus14-navlink">
					<svg viewBox="0 0 1024 1024" className="bus14-icon">
						<path
							d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
					</svg>
				</Link>
				<span className="bus14-text">
					<span>付款方式</span>
					<br></br>
				</span>
			</div>
			<div className="bus14-container2"></div>
			<span className="bus14-text3">
				<span>请填写您的卡详细信息</span>
				<br></br>
			</span>
			<div className="bus14-container3">
				<input
					type="text"
					placeholder="卡号"
					className="bus14-textinput input"
				/>
				<input
					type="text"
					placeholder="有效期至 (DD/MM/YY)"
					className="bus14-textinput1 input"
				/>
				<input
					type="text"
					placeholder="CCV"
					className="bus14-textinput2 input"
				/>
				<input
					type="text"
					placeholder="持卡人姓名"
					className="bus14-textinput3 input"
				/>
			</div>
			<div className="bus14-container4">
				<span className="bus14-text6">设置为主卡</span>
				<input type="checkbox" checked="true" className="bus14-checkbox"/>
			</div>
			<div className="bus14-container5">
				<Link to="/bus-13" className="bus14-navlink1">
					<img
						alt="image"
						src="/activity/Confirm.svg"
						className="bus14-image"
					/>
				</Link>
			</div>
		</div>
	)
}

export default Bus14
