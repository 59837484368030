import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-021.css'

const DutyFree021 = (props) => {
  return (
    <div className="duty-free021-container">
      <Helmet>
        <title>免税</title>
        <meta property="og:title" content="DutyFree-021 - exported project" />
      </Helmet>
      <div className="duty-free021-container01">
        <div className="duty-free021-container02">
          <div className="duty-free021-container03">
            <div className="duty-free021-container04">
              <Link to="/" className="duty-free021-navlink">
                <img
                  alt="image"
                  src="/dutyfree_assets/group%204568-200h.png"
                  className="duty-free021-image"
                />
              </Link>
              <Link to="/duty-free-01" className="duty-free021-navlink1">
                <div className="duty-free021-container05">
                  <span className="duty-free021-text">
                    <span>交付</span>
                    <br></br>
                  </span>
                  <div className="duty-free021-container06">
                    <span className="duty-free021-text03">
                    在KLIA提货
                    </span>
                    <img
                      alt="image"
                      src="/dutyfree_assets/expand_more_fill0_wght400_grad0_opsz48%202-200h.png"
                      className="duty-free021-image01"
                    />
                  </div>
                </div>
              </Link>
            </div>
            <div className="duty-free021-container07">
              <img
                alt="image"
                src="/dutyfree_assets/group%204638-200h.png"
                className="duty-free021-image02"
              />
            </div>
          </div>
          <h1 className="duty-free021-text04">
            <span>免税</span>
            <br></br>
          </h1>
          <span className="duty-free021-text07">
          在这里购买并安排机场接送或送货上门
          </span>
        </div>
        <div className="duty-free021-container08">
          <input
            type="text"
            placeholder="Search for duty free item"
            className="duty-free021-textinput input"
          />
          <img
            alt="image"
            src="/dutyfree_assets/mystery_fill0_wght400_grad0_opsz48%201-200h.png"
            className="duty-free021-image03"
          />
        </div>
        <div className="duty-free021-container09">
          <img
            alt="image"
            src="/dutyfree_assets/rectangle%20847-200h.png"
            className="duty-free021-image04"
          />
          <div className="duty-free021-container10">
            <img
              alt="image"
              src="/dutyfree_assets/ellipse%20391-200h.png"
              className="duty-free021-image05"
            />
            <img
              alt="image"
              src="/dutyfree_assets/ellipse%2041-200h.png"
              className="duty-free021-image06"
            />
            <img
              alt="image"
              src="/dutyfree_assets/ellipse%2041-200h.png"
              className="duty-free021-image07"
            />
          </div>
          <div className="duty-free021-container11">
            <div className="duty-free021-container12">
              <Link to="/duty-free-03" className="duty-free021-navlink2">
                <div className="duty-free021-container13">
                  <img
                    alt="image"
                    src="/dutyfree_assets/self_care_fill1_wght300_grad0_opsz48%2011-200h.png"
                    className="duty-free021-image08"
                  />
                </div>
              </Link>
              <div className="duty-free021-container14">
                <img
                  alt="image"
                  src="/dutyfree_assets/liquor_fill1_wght300_grad0_opsz48%201-200h.png"
                  className="duty-free021-image09"
                />
              </div>
              <div className="duty-free021-container15">
                <img
                  alt="image"
                  src="/dutyfree_assets/cable_fill1_wght300_grad0_opsz48%201-200h.png"
                  className="duty-free021-image10"
                />
              </div>
              <div className="duty-free021-container16">
                <img
                  alt="image"
                  src="/dutyfree_assets/workspace_premium_fill1_wght300_grad0_opsz48%201-200h.png"
                  className="duty-free021-image11"
                />
              </div>
            </div>
            <div className="duty-free021-container17">
              <span className="duty-free021-text08">
                <span>健康 &amp; 美丽</span>
                <br></br>
              </span>
              <span className="duty-free021-text11">
                <span>葡萄酒</span>
                <br></br>
              </span>
              <span className="duty-free021-text14">
                <span>电子产品</span>
                <br></br>
              </span>
              <span className="duty-free021-text17">
                <span>奢侈品</span>
                <br></br>
              </span>
            </div>
            <img
              alt="image"
              src="/dutyfree_assets/group%20256.svg"
              className="duty-free021-image12"
            />
          </div>
          <div className="duty-free021-container18">
            <img
              alt="image"
              src="/dutyfree_assets/image%2019-300h.png"
              className="duty-free021-image13"
            />
            <span className="duty-free021-text20">
            本月特色品牌
            </span>
            <div className="duty-free021-container19">
              <span className="duty-free021-text21">
                <span>查看全部</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/dutyfree_assets/expand_circle_down_fill1_wght300_grad0_opsz40%201.svg"
                className="duty-free021-image14"
              />
            </div>
            <div className="duty-free021-container20">
              <div className="duty-free021-container21">
                <img
                  alt="image"
                  src="/dutyfree_assets/rectangle%20849-200h.png"
                  className="duty-free021-image15"
                />
              </div>
              <div className="duty-free021-container22">
                <img
                  alt="image"
                  src="/dutyfree_assets/rectangle%20849-200h.png"
                  className="duty-free021-image16"
                />
              </div>
              <div className="duty-free021-container23">
                <img
                  alt="image"
                  src="/dutyfree_assets/rectangle%20849-200h.png"
                  className="duty-free021-image17"
                />
              </div>
            </div>
          </div>
          <span className="duty-free021-text24">给你</span>
          <div className="duty-free021-container24">
            <div className="duty-free021-container25">
              <div className="duty-free021-container26">
                <div className="duty-free021-container27">
                  <img
                    alt="image"
                    src="/dutyfree_assets/image%2020-200w.png"
                    className="duty-free021-image18"
                  />
                </div>
                <span className="duty-free021-text25">
                  <span>蓝带马爹利干邑</span>
                  <br></br>
                </span>
                <span className="duty-free021-text28">
                  <span>马币 546</span>
                  <br></br>
                  <br></br>
                </span>
              </div>
              <div className="duty-free021-container28">
                <div className="duty-free021-container29">
                  <img
                    alt="image"
                    src="/dutyfree_assets/image%2022-200w.png"
                    className="duty-free021-image19"
                  />
                </div>
                <span className="duty-free021-text32">轩尼诗V.S.O.P</span>
                <span className="duty-free021-text33">
                  <span>马币 546</span>
                  <br></br>
                  <br></br>
                </span>
              </div>
            </div>
            <div className="duty-free021-container30">
              <div className="duty-free021-container31">
                <div className="duty-free021-container32">
                  <img
                    alt="image"
                    src="/dutyfree_assets/image%2020-200w.png"
                    className="duty-free021-image20"
                  />
                </div>
                <span className="duty-free021-text37">
                  <span>蓝带马爹利干邑</span>
                  <br></br>
                </span>
                <span className="duty-free021-text40">
                  <span>马币 546</span>
                  <br></br>
                  <br></br>
                </span>
              </div>
              <div className="duty-free021-container33">
                <div className="duty-free021-container34">
                  <img
                    alt="image"
                    src="/dutyfree_assets/image%2022-200w.png"
                    className="duty-free021-image21"
                  />
                </div>
                <span className="duty-free021-text44">轩尼诗V.S.O.P</span>
                <span className="duty-free021-text45">
                  <span>马币 546</span>
                  <br></br>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DutyFree021
