import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-05.css'

const ERL05 = (props) => {
  return (
    <div className="erl05-container">
      <Helmet>
        <title>快铁</title>
        <meta property="og:title" content="ERL-05 - exported project" />
      </Helmet>
      <div className="erl05-container1">
        <img
          alt="image"
          src="/images/ERL04-express.svg"
          loading="eager"
          className="erl05-image"
        />
        <Link to="/erl-04" className="erl05-navlink">
          <img
            alt="image"
            src="/Homepage/black-square-for-overlay.svg"
            className="erl05-overlay"
          />
        </Link>
        <img
          alt="image"
          src="/images/ERL - select.svg"
          className="erl05-image1"
        />
        <div className="erl05-container2"></div>
        <Link to="/erl-06" className="erl05-navlink1"></Link>
      </div>
    </div>
  )
}

export default ERL05
