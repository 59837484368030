import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './erl-04.css'

const ERL04 = (props) => {
  return (
    <div className="erl04-container">
      <Helmet>
        <title>快铁</title>
        <meta property="og:title" content="ERL-04 - exported project" />
      </Helmet>
      <div className="erl04-container1">
        <img
          alt="image"
          src="/images/ERL04-express.svg"
          loading="eager"
          className="erl04-image"
        />
        <div className="erl04-container2"></div>
        <Link to="/erl-01" className="erl04-navlink"></Link>
        <Link to="/erl-05" className="erl04-navlink1"></Link>
      </div>
    </div>
  )
}

export default ERL04
