import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-03.css'

const MeetGreet03 = (props) => {
  return (
    <div className="meet-greet03-container">
      <Helmet>
        <title>见面会</title>
        <meta property="og:title" content="Meet-Greet-03 - exported project" />
      </Helmet>
      <div className="meet-greet03-container1">
        <Link to="/meet-greet-02" className="meet-greet03-navlink">
          <svg viewBox="0 0 1024 1024" className="meet-greet03-icon">
            <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
          </svg>
        </Link>
        <span className="meet-greet03-text">
          <span>预订抵达服务</span>
          <br className="meet-greet03-text02"></br>
          <br></br>
          <br></br>
        </span>
        <Link to="/meet-greet-01" className="meet-greet03-navlink1">
          <svg viewBox="0 0 1024 1024" className="meet-greet03-icon2">
            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
          </svg>
        </Link>
      </div>
      <div className="meet-greet03-container2"></div>
      <span className="meet-greet03-text05">
        <span>步骤 1/3</span>
        <br></br>
      </span>
      <span className="meet-greet03-text08">
        <span>请提供您抵达航班的详细信息。</span>
        <br className="meet-greet03-text10"></br>
        <br></br>
        <br></br>
        <br></br>
      </span>
      <input
        type="text"
        placeholder="到达航班号"
        className="meet-greet03-textinput input"
      />
      <span className="meet-greet03-text14">
        <span>航站楼</span>
        <br></br>
      </span>
      <div className="meet-greet03-container3">
        <img
          alt="image"
          src="/images/Meet&Greet8.svg"
          className="meet-greet03-image"
        />
        <img
          alt="image"
          src="/images/Meet&Greet9.svg"
          className="meet-greet03-image1"
        />
      </div>
      <div className="meet-greet03-container4">
        <div className="meet-greet03-container5">
          <span className="meet-greet03-text17">
            <span>马币 100.00</span>
            <br></br>
          </span>
          <span className="meet-greet03-text20">
            <span>总共支付</span>
            <br></br>
            <br></br>
            <br></br>
          </span>
        </div>
        <Link to="/meet-greet-04" className="meet-greet03-navlink2">
          <img
            alt="image"
            src="/images/mytrip-next-btn.svg"
            className="meet-greet03-image2"
          />
        </Link>
      </div>
    </div>
  )
}

export default MeetGreet03
