import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-09.css'

const DutyFree09 = (props) => {
  return (
    <div className="duty-free09-container">
      <Helmet>
        <title>免税</title>
        <meta property="og:title" content="DutyFree-09 - exported project" />
      </Helmet>
      <div className="duty-free09-container1">
        <img
          alt="image"
          src="/images/Duty free review - cont.svg"
          className="duty-free09-image"
        />
        <Link to="/duty-free-08" className="duty-free09-navlink">
          <div className="duty-free09-container2">
            <img
              alt="image"
              src="/Homepage/black-square-for-overlay.svg"
              className="duty-free09-image1"
            />
          </div>
        </Link>
        <div className="duty-free09-container3">
          <div className="duty-free09-container4"></div>
          <h4 className="duty-free09-text">
            <span>移除</span>
            <br></br>
          </h4>
          <div className="duty-free09-container5">
            <div className="duty-free09-container6">
              <span className="duty-free09-text03">
                <span>1</span>
                <br></br>
              </span>
              <span className="duty-free09-text06">
                <span>2</span>
                <br></br>
              </span>
              <span className="duty-free09-text09">
                <span>3</span>
                <br></br>
              </span>
            </div>
            <div className="duty-free09-container7"></div>
            <Link to="/duty-free-07" className="duty-free09-button button">
              <span className="duty-free09-text12">
                <span>完成</span>
                <br></br>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DutyFree09
