import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './hotel-gallery.css'

const HotelGallery = (props) => {
  return (
    <div className="hotel-gallery-container">
      <Helmet>
        <title>HotelGallery - exported project</title>
        <meta property="og:title" content="HotelGallery - exported project" />
      </Helmet>
      <div className="hotel-gallery-container1">
        <img
          alt="image"
          src="/activity/hotel-gallery1.svg"
          className="hotel-gallery-image"
        />
        <Link to="/hotel-product" className="hotel-gallery-navlink">
                
        </Link>
      </div>
    </div>
  )
}

export default HotelGallery
