import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './meet-greet-09-edit.css'

const MeetGreet09Edit = (props) => {
  return (
    <div className="meet-greet0-edit-container">
      <Helmet>
        <title>见面会</title>
        <meta
          property="og:title"
          content="Meet-Greet-09-Edit - exported project"
        />
      </Helmet>
      <div className="meet-greet0-edit-container1">
        <Link to="/meet-greet-08" className="meet-greet0-edit-navlink">
          <svg viewBox="0 0 1024 1024" className="meet-greet0-edit-icon">
            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
          </svg>
        </Link>
        <span className="meet-greet0-edit-text">
          <span>付款方式</span>
          <br></br>
        </span>
      </div>
      <div className="meet-greet0-edit-container2"></div>
      <span className="meet-greet0-edit-text3">
        <span>请填写您的卡详细信息</span>
        <br></br>
      </span>
      <div className="meet-greet0-edit-container3">
        <input
          type="text"
          value="11122910"
          placeholder="卡号"
          className="meet-greet0-edit-textinput input"
        />
        <input
          type="text"
          value="22/11/2027"
          placeholder="有效期 (DD/MM/YY)"
          className="meet-greet0-edit-textinput1 input"
        />
        <input
          type="text"
          value="133"
          placeholder="CCV"
          className="meet-greet0-edit-textinput2 input"
        />
        <input
          type="text"
          value=""
          placeholder="持卡人姓名"
          className="meet-greet0-edit-textinput3 input"
        />
      </div>
      <div className="meet-greet0-edit-container4">
        <span className="meet-greet0-edit-text6">Set as primary card</span>
        <input
          type="checkbox"
          checked="true"
          className="meet-greet0-edit-checkbox"
        />
      </div>
      <div className="meet-greet0-edit-container5">
        <Link to="/meet-greet-08" className="meet-greet0-edit-navlink1">
          <img
            alt="image"
            src="/images/Confirm.svg"
            className="meet-greet0-edit-image"
          />
        </Link>
      </div>
    </div>
  )
}

export default MeetGreet09Edit
