import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import './my-trip-04.css'

const MyTrip04 = (props) => {
	return (
		<div className="my-trip04-container">
			<Helmet>
				<title>MyTrip-04 - exported project</title>
				<meta property="og:title" content="MyTrip-04 - exported project"/>
			</Helmet>
			<div className="my-trip04-container1">
				<div className="my-trip04-container2">
					<Link to="/my-trip-03" className="my-trip04-navlink">
						<img
							alt="image"
							src="/Homepage/back-arrow.svg"
							className="my-trip04-image"
						/>
					</Link>
					<h2 className="my-trip04-heading">
						<span>添加酒店</span>
						<br></br>
					</h2>
				</div>
				<div className="my-trip04-container3"></div>
				<h2 className="my-trip04-text2">
					<span>为您的旅行命名</span>
					<br></br>
				</h2>
				<div className="my-trip04-container4">
					<span className="my-trip04-text5">
						<span>洛杉矶</span>
						<br></br>
					</span>
				</div>
			</div>
			<div className="my-trip04-container5">
				<div className="my-trip04-container6"></div>
				<Link to="/my-trip-05" className="my-trip04-navlink1">
					<img
						alt="image"
						src="/activity/Confirm.svg"
						className="my-trip04-image1"
					/>
				</Link>
			</div>
		</div>
	)
}

export default MyTrip04
