import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './bus-07.css'

const Bus07 = (props) => {
  return (
    <div className="bus07-container">
      <Helmet>
        <title>Bus-07 - exported project</title>
        <meta property="og:title" content="Bus-07 - exported project" />
      </Helmet>
      <div className="bus07-container1">
        <img
          alt="image"
          src="/activity/Buses - checkout3.svg"
          className="bus07-image"
        />
        <div className="bus07-container2"></div>
        <img
          alt="image"
          src="/activity/Payment details.svg"
          className="bus07-image1"
        />
      </div>
      <Link to="/bus-06" className="bus07-navlink">
        Text
      </Link>
    </div>
  )
}

export default Bus07
