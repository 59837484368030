import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-03.css'

const DutyFree03 = (props) => {
  return (
    <div className="duty-free03-container">
      <Helmet>
        <title>免税的</title>
        <meta property="og:title" content="DutyFree-03 - exported project" />
      </Helmet>
      <div className="duty-free03-container1">
        <img
          alt="image"
          src="/images/Duty free list.svg"
          className="duty-free03-image"
        />
        <Link to="/duty-free-02" className="duty-free03-navlink">
           
        </Link>
        <Link to="/duty-free-08" className="duty-free03-navlink1">
           
        </Link>
        <Link to="/duty-free-04" className="duty-free03-navlink2">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree03
