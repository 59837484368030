import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './duty-free-02.css'

const DutyFree02 = (props) => {
  return (
    <div className="duty-free02-container">
      <Helmet>
        <title>免税的</title>
        <meta property="og:title" content="DutyFree-02 - exported project" />
      </Helmet>
      <div className="duty-free02-container1">
        <img
          src="/images/Duty free.svg"
          className="duty-free02-image"
        />
        <Link to="/extend-explore-page" className="duty-free02-navlink">
           
        </Link>
        <Link to="/duty-free-01" className="duty-free02-navlink1">
           
        </Link>
        <Link to="/duty-free-03" className="duty-free02-navlink2">
           
        </Link>
      </div>
    </div>
  )
}

export default DutyFree02
